import React from 'react';
import Layout from '../components/Layout/Layout';

const NotFound = () => {
    return (
        <>
               <div className='not-foundPageMain'>
               <div className="not-foundPage">
                    <h1>404</h1>
                    <p>Oops! Page Not Found</p>
                    <a href="/">Go Back Home</a>
                </div>
               </div>
                
           

        </>

    );
};
export default NotFound;
