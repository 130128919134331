import { message, notification, Table } from 'antd';
import { useEffect, useState } from 'react';
import api from '../../api/api';
import Cookies from 'js-cookie';
import { Toaster } from 'react-hot-toast';
import laptopBlack from '../../images/black/5_Black.png'
import laptopgray from '../../images/gray/pic2.png'
import { Modal, Button } from 'antd';
import useSaleAlert from '../../pages/ShowNotification'
import book1 from '../../images/book/marketing.jpg'
import book2 from '../../images/book/security.jpg'
import book3 from '../../images/book/mastery.jpg'
import book4 from '../../images/book/profe.jpg'
import book5 from '../../images/book/creative.jpg'


function MyAccount() {
    const { saleAlert, loading } = useSaleAlert();
    const [orders, setOrders] = useState([]);
    const [userName, setUserName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

  

    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [stateNew, setStateNew] = useState([]);
    const [cityNew, setCityNew] = useState([]);
    const [book, setBook] = useState([])
    const [bookId, setBookID] = useState([])


    const user_id = String(Cookies.get('id'));

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        try {
            const idd = String(Cookies.get('idd'));
            await api.put(`/auth/editUser/${idd}`, { address, state, city, pinCode });
            notification.success({ message: "Profile updated successfully!" });
            handleCancel();
            fetchUserDetails();
        } catch (error) {
            // console.error("Error updating profile:", error);
            // notification.error({ message: "Failed to update profile." });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEditing(false);
    };

    const fetchUserDetails = async () => {
        const idd = String(Cookies.get('idd'));
        try {
            const response = await api.get(`/auth/getUser/${idd}`);


            const { success, message, data } = response.data;

            if (success) {
                setUserDetails(data);
                setAddress(data.address);
                setState(data.state);
                setCity(data.city);
                setPinCode(data.pinCode);

            } else {
                notification.error({ message });
            }
        } catch (error) {
            // console.error("Error fetching user details:", error);
            // notification.error({ message: "Failed to fetch user details." });
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);


    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options).replace(',', ' at');
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await api.get(`orders/getUserOrders/${user_id}`);
              
                const { data } = response.data;
                const bookIds = data.map(order => order.book_id).filter(id => id);

                setOrders(data);

                setBookID(bookIds);
                setUserName(data[0])


                if (data.length > 0) {
                    Cookies.set('paymentstatus', data[0].paymentstatus, { expires: 7 });
                }
            } catch (error) {
                // console.error("Error fetching orders:", error);
            }
        };

        // Fetch orders if user_id is available
        if (user_id) {
            fetchOrders();
        }
    }, []);

    //  ******Fetch data
    useEffect(() => {
        api.get('/states/getAllStates')
            .then((res) => {

                setStateNew(res.data.data);
                // setSetCountryId(res.data.data.country_id)
            })
            .catch((res) => {
                // console.error(res);
            })
    }, [])


    // Fetch cities by selected state ID
    const fetchCitiesByState = async (stateId) => {
        try {
            const response = await api.get(`/cities/getCitiesByState/${stateId}`);
            setCityNew(response.data.data);

        } catch (error) {
            // console.error("Error fetching cities:", error);
            // notification.error({ message: "Failed to fetch cities." });
        }
    };

    // Fetch cities based on selected state
    useEffect(() => {
        if (state) {
            fetchCitiesByState(state);
        }
    }, [state]);

    // ****************************
    useEffect(() => {
        api.get(`/books/getBook/${bookId}`)
            .then((res) => {
                setBook(res.data.data.description)
            })
            .catch((error) => {
                // console.error(error)
            })
    }, [bookId]);


    return (
        <>
            <div className="my-account" style={{ marginTop: "110px", padding: "0 20px" }}>
                <div>
                    {orders.length > 0 &&
                        orders.map((elem, ind) => (
                            <>
                                <div key={ind} className='myorderAccountdata'>
                                    <div>
                                        <div className='myorderAccountdataPayment'>
                                            <h3>Order ID: {elem.order_id}</h3>
                                            <div> <p>
                                                {elem.paymentstatus === 1
                                                    ? "Payment Successful"
                                                    : elem.paymentstatus === 0
                                                        ? "Payment Pending"
                                                        : `Status: ${elem.paymentstatus}`}
                                            </p></div>
                                        </div>
                                    </div>

                                    <div className='my-profile-edit'>
                                        <h2>Welcome {userName.user_name}</h2>
                                        <div>
                                            <h3 onClick={showModal}>My Profile</h3>
                                        </div>
                                    </div>

                          {/* Mobile version start */}
                                 <div className='myAccountmobileversion'
                                 >
                                 <div className='my-profile-editt'>
                                        <h2>Welcome {userName.user_name}</h2>
                        
                                       <h3>Order ID: {elem.order_id}</h3>
                                    </div> 

                                    <div>
                                        <div className='myorderAccountdataPaymentt'>
                                           
                                            <h3 onClick={showModal}>My Profile</h3>
                                       
                                            <div> <p>
                                                {elem.paymentstatus === 1
                                                    ? "Payment Successful"
                                                    : elem.paymentstatus === 0
                                                        ? "Payment Pending"
                                                        : `Status: ${elem.paymentstatus}`}
                                            </p></div>
                                        </div>
                                    </div>
                                           
                                 </div>

                               {/* Mobile version end */}
                                </div>
                                <p className='myAccountDateformat'
                                >{formatDate(elem.createdAt)}</p>

                                <div className='myAccount-order-item'>
                                    <h3>Order Item</h3>
                                    <div className='OrderItem-main-imageChooese'>
                                        <div
                                        className='OrderItem-main-imageChooesedivfirst'
                                        >
                                            {
                                                elem.product_colour === "Grey" ? (
                                                    <div className='OrderItemimageChooese'>
                                                        <img src={laptopgray} alt="laptop gray" />
                                                    </div>
                                                ) : elem.product_colour === "Black" ? (
                                                    <div className='OrderItemimageChooese'>
                                                        <img src={laptopBlack} alt="laptop black" />
                                                    </div>
                                                ) : null
                                            }


                                            <div className='OrderItemimageChooese-besideContent'>
                                                <p
                                                className='OrderItemimageChooese-besideContentpara1'
                                                >Laptop SolarisBook 14 OLED</p>
                                                <p
                                                className='OrderItemimageChooese-besideContentpara2'
                                                >Intel i5 13th Generation Processor,8gb RAM,256 SSD
                                                    35.5-CM  Full HD+, XE Iris Graphics Card WiFi 6E
                                                    Windows Pro 11 AI & MS Office 365
                                                </p>

                                                <p className='OrderItemimageChooeseColor'>
                                                    {elem.product_colour === "Grey" ? (
                                                        <>
                                                            Colour Grey <div className='boxgrayy'></div>
                                                        </>
                                                    ) : elem.product_colour === "Black" ? (
                                                        <>
                                                            Colour Black <div className='boxBlack'></div>
                                                        </>
                                                    ) : null}
                                                </p>
                                                <p>Amount to be pay, Once Order is ready!! : Rs. 14,999/-</p>
                                            </div>
                                        </div>
                                        <div className='PreOrderConfirmStatic1'>
                                            <p>Status </p>
                                            <p>Pre Order Confirm</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Section 2 */}
                                <div className='myAccount-order-item' style={{ marginTop: "20px", paddingTop: "20px" }}>
                                    <div className='OrderItem-main-imageChooese'>

                                        <div className='OrderItem-main-section2'>
                                            {
                                                elem.book_name === "Digital Marketing Pro" ? (
                                                    <div className='OrderItemimageChooese2'>
                                                        <img src={book1} alt="digital book" />
                                                    </div>
                                                ) : elem.book_name === "Tech Security & Development" ? (
                                                    <div className='OrderItemimageChooese'>
                                                        <img src={book2} alt="digital book" />
                                                    </div>
                                                ) : elem.book_name === 'Advanced Tech Mastery' ? (
                                                    <div className='OrderItemimageChooese'>
                                                        <img src={book3} alt="digital book" />
                                                    </div>
                                                ) : elem.book_name === 'Full-Stack Development Proficiency' ? (
                                                    <div className='OrderItemimageChooese'>
                                                        <img src={book4} alt="digital book" />
                                                    </div>
                                                ) : elem.book_name === "Creative Suite Mastery" ? (
                                                    <div className='OrderItemimageChooese'>
                                                        <img src={book5} alt="digital book" />
                                                    </div>
                                                ) : "null"
                                            }
                                            <div className='OrderItemimageChooese-besideContent2'>
                                                <p
                                                   className='OrderItemimageChooese-besideContent2para1'
                                                >Your Digital Video Course</p>
                                                <p>
                                                    {elem.book_name}
                                                </p>

                                                <p>{book}</p>

                                                <p>  <a href={elem.book_link}>Download Course</a></p>
                                            </div>
                                        </div>

                                        <div className='PreOrderConfirmStaticsection2'>
                                            <p>Status  </p>
                                            <p>Order Delivered</p>

                                        </div>

                                    </div>

                                </div>
                                {/* total */}
                                <div style={{ margin: "30px" }} >
                                    <h3>Prebooking Digital Course Amount Paid : {elem.amount}</h3>
                                </div>


                                <Modal
                                    className='myProfileeditmodal'
                                    title="Edit Profile"
                                    open={isModalOpen}
                                    onCancel={handleCancel}
                                    footer={[
                                        <Button key="cancel" onClick={handleCancel}>
                                            Cancel
                                        </Button>,
                                        <Button
                                            key="edit"
                                            onClick={async () => {
                                                if (isEditing) {
                                                    await handleOk();
                                                    setIsEditing(false);
                                                } else {

                                                    setIsEditing(true);
                                                }
                                            }}
                                        >
                                            {isEditing ? "Save" : "Edit"}
                                        </Button>
                                    ]}
                                >
                                    <div className='profileEditMain' style={{ padding: '30px', borderRadius: '12px', maxWidth: '600px', margin: 'auto', backgroundColor: '#f8f9fa', boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)' }}>
                                        <h2 style={{ textAlign: 'center', color: '#343a40', marginBottom: '25px' }}>Edit Your Profile</h2>
                                        <div className="input-row">
                                            <div className="input-pair" >

                                                <div className="input-group" style={{ flex: '1' }}>
                                                    <label style={{ fontWeight: '500', marginBottom: '5px', display: 'block', color: '#495057' }}>Address:</label>
                                                    {isEditing ? (
                                                        <input
                                                            type="text"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                            style={{ padding: '8px', border: '1px solid #ced4da', borderRadius: '6px', width: '100%', transition: 'border-color 0.3s' }}
                                                            onFocus={(e) => e.target.style.borderColor = '#80bdff'}
                                                            onBlur={(e) => e.target.style.borderColor = '#ced4da'}
                                                        />
                                                    ) : (
                                                        <span style={{ padding: '18px', width: "100%", border: '1px solid #ced4da', borderRadius: '6px', backgroundColor: '#ffffff', display: 'inline-block' }}>{address}</span>
                                                    )}
                                                </div>
                                                <div className="input-group" style={{ flex: '1' }}>
                                                    <label style={{ fontWeight: '500', marginBottom: '5px', display: 'block', color: '#495057' }}>State:</label>
                                                    {isEditing ? (
                                                        <select
                                                            value={state}
                                                            onChange={(e) => {
                                                                const selectedStateId = e.target.value;
                                                                setState(selectedStateId);
                                                                if (selectedStateId) {
                                                                    fetchCitiesByState(selectedStateId);
                                                                }
                                                            }}
                                                            style={{ padding: '8px', width: "200px", border: '1px solid #ced4da', borderRadius: '6px', width: '100%', backgroundColor: '#fff', cursor: 'pointer' }}
                                                        >
                                                            <option value="">Select State</option>
                                                            {stateNew.length > 0 &&
                                                                stateNew.map((elem) => (
                                                                    <option key={elem.id} value={elem.id}>
                                                                        {elem.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    ) : (
                                                        <span style={{ padding: '18px', width: "100%", border: '1px solid #ced4da', borderRadius: '6px', backgroundColor: '#ffffff', display: 'inline-block' }}>{stateNew.find((s) => s.id === parseInt(state))?.name || state}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="input-roww">
                                            <div className="input-group" style={{ flex: '1', marginRight: '15px' }}>
                                                <label style={{ fontWeight: '500', marginBottom: '5px', display: 'block', color: '#495057' }}>City:</label>
                                                {isEditing ? (
                                                    <select
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        style={{ padding: '8px', border: '1px solid #ced4da', borderRadius: '6px', width: '100%', backgroundColor: '#fff', cursor: 'pointer' }}
                                                    >
                                                        <option value="">Select City</option>
                                                        {cityNew.length > 0 &&
                                                            cityNew.map((cityItem) => (
                                                                <option key={cityItem.id} value={cityItem.id}>
                                                                    {cityItem.city}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                ) : (
                                                    <span 
                                                    className='myaccountcitynoedit'
                                                    style={{ padding: '18px', border: '1px solid #ced4da', borderRadius: '6px', backgroundColor: '#ffffff', display: 'inline-block' }}>{cityNew.find((c) => c.id === parseInt(city))?.city || city}</span>
                                                )}
                                            </div>

                                            <div className="input-group" style={{ flex: '1', marginRight: '15px' }}>
                                                <label style={{ fontWeight: '500', marginBottom: '5px', display: 'block', color: '#495057' }}>PinCode:</label>
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        value={pinCode}
                                                        onChange={(e) => setPinCode(e.target.value)}
                                                        style={{ padding: '8px', border: '1px solid #ced4da', borderRadius: '6px', width: '100%', transition: 'border-color 0.3s' }}
                                                        onFocus={(e) => e.target.style.borderColor = '#80bdff'}
                                                        onBlur={(e) => e.target.style.borderColor = '#ced4da'}
                                                    />
                                                ) : (
                                                    <span className='myaccountcitynoedit' style={{ padding: '18px', border: '1px solid #ced4da', borderRadius: '6px', backgroundColor: '#ffffff', display: 'inline-block' }}>{pinCode}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        ))}
                </div>
            </div>
            <div style={{ position: 'fixed', left: 0, bottom: 0, zIndex: 9999 }}>
                <Toaster position="bottom-left" />
            </div>
        </>
    );
}
export default MyAccount;
