import React, { useState } from 'react';
import { FaBars } from "react-icons/fa";
import AdminAffiliate from '../dashboard/AffiliateDetailsPage';
import AdminUserPage from '../dashboard/AdminUserPage';
import AdminProductPage from '../dashboard/AdminProductPage';
import AdminOrdersPage from '../dashboard/AdminOrdersPage';
import Cookies from 'js-cookie';
import logo from '../assets/images/logodashboard.svg';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { FaUserAlt } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { FaBox } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa"; 
import { BiSolidBank } from "react-icons/bi";
import AdminDashboardBankVerify from '../dashboard/AdminDashboardBankVerify';
import AdminAffiliateSlab from '../dashboard/AdminAffiliateSlab';
import { FaBookOpen } from "react-icons/fa";
import AdminBook from '../dashboard/AdminBook';

const Dashboard = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState('AdminUserPage');

    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handlePageChange = (page) => {
        setSelectedPage(page);
    };

    // const role = Cookies.get("role");
    
    const handlelogout = () => {
        Cookies.remove("role");
        navigate("/");
        notification.success({ message: "Logout Successfully, Thank you!" });
        window.location.reload('')
    };

    const renderPage = () => {
        switch (selectedPage) {
            case 'AdminUserPage':
                return <AdminUserPage />;
                case 'AdminBankVerify':
                    return <AdminDashboardBankVerify/>;
            case 'affiliate':
                return <AdminAffiliate/>;
                case 'AdminAffiliateSlab':
                    return <AdminAffiliateSlab/>;
            case 'AdminProductPage':
                return <AdminProductPage/>;
            case 'AdminOrdersPage':
                return <AdminOrdersPage/>;

                case 'AdminBook':
                    return <AdminBook/>;
           
            default:
                return <AdminUserPage/>;
        }
    };

    return (
        <div className={`dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <header className="dashboard-header">
                <div className="logo">
                    <img src={logo} alt="" />
                </div>
                <button className="toggle-btn" onClick={handleToggle}>
                    <div className='dashboardtogglecircle'>
                    <FaBars />
                    </div>
                    
                </button>
                <button
                    onClick={handlelogout}
                    className="logoutdashboard"
                >Logout</button>
            </header>

            <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <nav>
                    <ul>
                        <li onClick={() => handlePageChange('AdminUserPage')}><FaUserAlt size={23}/> User</li>
                        <li onClick={() => handlePageChange('AdminBankVerify')}><BiSolidBank size={23}/>BankVerify</li>
                        <li onClick={() => handlePageChange('affiliate')}>  <FaHandshake size={24} />Affiliate</li>
                        <li onClick={() => handlePageChange('AdminAffiliateSlab')}>  <FaHandshake size={24} />Affiliate Slab</li>
                        <li onClick={() => handlePageChange('AdminProductPage')}><FaBox size={20} />Product</li>
                        <li onClick={() => handlePageChange('AdminOrdersPage')}>  <FaClipboardList size={24} />Orders</li>
                        <li onClick={() => handlePageChange('AdminBook')}>  <FaBookOpen size={24}/>Digital Book</li>
                    </ul>
                </nav>
            </aside>

            <main className="dashboard-content">
                {renderPage()}
            </main>
        </div>
    );
};
export default Dashboard;
