import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import api from '../../../src/api/api';
import CommonButton from "../commonButton/CommonButton";
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

// Validation schema
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters long.")
        .required("Password is required."),
});

const AffiliateSignin = ({ onSuccess }) => {

    const navigate = useNavigate();

    // Handle form submission 
    const handleSubmit = async (values, { resetForm }) => {
        const formValues = {
            email: values.email,
            password: values.password.trim(),
        };
        try {
            const response = await api.post('/affiliate/affiliateSignIn', formValues);

            if (response.status === 200) {
                Cookies.set('affiliateId', response.data.data.id, { secure: true, sameSite: 'Strict' });
                Cookies.set('role', response.data.data.role, { secure: true, sameSite: 'Strict' });
                Cookies.set('name', response.data.data.name, { secure: true, sameSite: 'Strict' });
                  
                Cookies.set('code', response.data.data.code, { secure: true, sameSite: 'Strict' });
                Cookies.set('commissionvalue', response.data.data.commissionvalue, { secure: true, sameSite: 'Strict' });
                Cookies.set('discount', response.data.data.discount, { secure: true, sameSite: 'Strict' });

                Cookies.set('bankVerify', response.data.data.bankVerify, { secure: true, sameSite: 'Strict' });

                notification.success({ message: "Form submitted successfully!" });
                navigate('/affiliate-details');
                resetForm(); 
                if (onSuccess) onSuccess();

            } else {
                notification.error({ message: response.data.message });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                notification.error({ message: error.response.data.message });
            } else {
                notification.error({ message: "An error occurred while submitting the form." });
            }
        }
    };

    return (
        <Layout>
            <PageTitle
                gredTitle="Affiliate SignIn"
                title=""
                mainPara="Please sign in to access your affiliate dashboard."
            />
            <div className="login-container" style={{ marginTop: "20px" }}>
                <div className="form-containerAffiliatesignUp">
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit} className="row">
                                <div className="form-group col-md-12">
                                    <label className="form-label">Email ID</label>
                                    <Field name="email" className="form-control" />
                                    <ErrorMessage name="email" component="span" className="error" />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="form-label">Password</label>
                                    <Field
                                        name="password"
                                        type="password"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="password" component="span" className="error" />
                                </div>


                                <CommonButton
                                    type="submit"
                                    style={{ margin: '10px auto' }}
                                    buttonText="Submit"
                                />

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </Layout>
    );
};

export default AffiliateSignin;
