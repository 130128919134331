import React from "react";
import Layout from "../components/Layout/Layout";
import PageTitle from "../components/pageTitle/PageTitle";
import PragraphBox from "../components/innerpagecomponents/PragraphBox";
import { Toaster} from 'react-hot-toast';
import useSaleAlert from '../pages/ShowNotification';

function Refund() {
    const { saleAlert, loading } = useSaleAlert(); 
    return (
        <Layout>
            <PageTitle gredTitle="Refund & Return" title="Policy" />
            <div className="boxWrpper">
                <PragraphBox
                    contentText="Digital Course Refund Policy:"
                    para={`All sales for digital courses are final and non-refundable. By purchasing a digital course, you acknowledge that you are acquiring only the course content, and refunds will not be provided under any circumstances. This includes situations where the customer mistakenly believes the purchase is connected to the pre-order of the Solaris Book Laptop.
.`}
                />

                <PragraphBox
                    contentText="Solaris Book Laptop Refund Policy:"
                    para={`We do not offer refunds for the Solaris Book Laptop. However, in the event that a product arrives defective or a part is found to be faulty, we will assist you in getting the defective part replaced or, if necessary, replace the entire product in 7 working days subject to warantty. If you encounter any issues with your Solaris Book Laptop, please contact our customer service team for assistance, and we will ensure that any defects are addressed promptly..`}
                />

                <PragraphBox
                    contentText="Replacement of Defective Items:"
                    para={`If the product is deemed defective, Solaris Comtech will provide replacement parts or a complete replacement of the product, depending on the nature of the defect. Any claims regarding defects must be made within the warranty period, and proof of the defect must be provided to initiate the replacement process.`}
                />


                <PragraphBox
                    contentText="No Return Policy:"
                    para={`We do not offer returns for any products sold, including digital courses and the Solaris Book Laptop. Once a purchase is made, it is considered final. Returns are not accepted for reasons such as 
                        dissatisfaction with the product, change of mind, or incorrect
                         purchase decisions. <br> <br> 

                         For the Solaris Book Laptop, we stand behind the quality and durability of our products.
                          While we do not accept returns, we are committed to ensuring that any defects or faults in the product are swiftly resolved. As outlined in our Replacement of Defective Items policy, we will provide replacements for any defective 
                         components or the entire product, if necessary, within the warranty period. 
                         <br><br> 
                         If you have any questions about the product before purchasing, we recommend contacting our support team to clarify any details to ensure that the product meets your expectations and needs.
                         `}
                />
            </div>      
            <Toaster position="bottom-left" reverseOrder={false} />   
        </Layout>
    );
}
export default Refund;