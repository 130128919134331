import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Layout from "../../components/Layout/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import api from '../../../src/api/api';
import CommonButton from "../commonButton/CommonButton";
import { Dropdown, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'

// Validation schema
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Name should only contain letters.")
        .required("Name is required."),
    email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number.")
        .required("Mobile number is required."),
    address: Yup.string()
        .required("Address is required."),
    state: Yup.string()
        .required("State is required."),
    city: Yup.string()
        .required("City is required."),
    pinCode: Yup.string()
        .matches(/^[0-9]{6}$/, "Please enter a valid 6-digit pinCode.")
        .required("Pincode is required."),
});

const AffiliateSignup = ({ onSuccess }) => {
    const navigate = useNavigate();
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    // Fetch states on component mount
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await api.get("/states/getAllStates");
                if (response.status === 200) {
                    setStates(response.data.data);
                } else {
                    notification.error({ message: "Failed to fetch states." });
                }
            } catch (error) {
                notification.error({ message: "An error occurred while fetching states." });
            }
        };
        fetchStates();
    }, []);

    // Fetch cities when a state is selected
    const handleStateChange = async (stateId, setFieldValue) => {
        if (stateId) {
            try {
                const response = await api.get(`/cities/getCitiesByState/${stateId}`);
                if (response.status === 200) {
                    setCities(response.data.data);
                    setFieldValue("city", "");  // Clear city when state changes
                } else {
                    setCities([]);
                    notification.error({ message: "Failed to fetch cities." });
                }
            } catch (error) {
                setCities([]);
                notification.error({ message: "An error occurred while fetching cities." });
            }
        } else {
            setCities([]);
            setFieldValue("city", "");
        }
    };

    // Handle form submission 
    const handleSubmit = async (values, { resetForm }) => {
        const formValues = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            address: values.address,
            state: values.state,
            city: values.city,
            pinCode: values.pinCode,
            country: "1",
        };
    
        try {
            const response = await api.post('/affiliate/createAffiliate', formValues);
            if (response.status === 200) {
                // Cookies.set('id', response.data.data, { secure: true, sameSite: 'Strict'});
                notification.success({ message: "Form submitted successfully!" });
                navigate("/affiliate-signin")
                resetForm();
                if (onSuccess) onSuccess();
            } else {
                notification.error({ message: response.data.message });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                notification.error({ message: error.response.data.message });
            } else {
                notification.error({ message: "An error occurred while submitting the form." });
            }
        }
    };

    return (
        <Layout>
            <PageTitle
                gredTitle="Affiliate SignUp"
                title=""
                mainPara="At Solaris Comtech Laptop, we are passionate about innovation and dedicated to delivering high-quality technology products. Our flagship product, the Solaris Book, embodies our commitment to excellence, cutting-edge design, and user-friendly functionality."
            />
            <div className="boxWrpper">
                <div className="form-containerAffiliatesignUp">
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            phone: "",
                            address: "",
                            state: "",
                            city: "",
                            pinCode: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, values, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} className="row">
                                <div className="form-group col-md-6">
                                    <label className="form-label">Full Name</label>
                                    <Field name="name" className="form-control" />
                                    <ErrorMessage name="name" component="span" className="error" />
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="form-label">Email ID</label>
                                    <Field name="email" className="form-control" />
                                    <ErrorMessage name="email" component="span" className="error" />
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="form-label">Mobile Number</label>
                                    <Field
                                        name="phone"
                                        className="form-control"
                                        maxLength="10"
                                    />
                                    <ErrorMessage name="phone" component="span" className="error" />
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="form-label">Address</label>
                                    <Field name="address" className="form-control" />
                                    <ErrorMessage name="address" component="span" className="error" />
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="form-label">State</label>
                                    <select
                                        name="state"
                                        className="form-control"
                                        onChange={(e) => {
                                            const stateId = e.target.value;
                                            setFieldValue("state", stateId);
                                            handleStateChange(stateId, setFieldValue);
                                        }}
                                        value={values.state || ''}
                                        style={{
                                            appearance: 'none',
                                            WebkitAppearance: 'none',
                                            MozAppearance: 'none',
                                            paddingRight: '30px',
                                            background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23999" d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center`,
                                            backgroundColor: '#fff',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            color: '#333'
                                        }}
                                    >
                                        <option value="">Select State</option>
                                        {states.length > 0 &&
                                            states.map((state) => (
                                                <option key={state.id} value={state.id}>
                                                    {state.name}
                                                </option>
                                            ))}
                                    </select>
                                    <ErrorMessage name="state" component="span" className="error" />
                                </div>


                                <div className="form-group col-md-6">
                                    <label className="form-label">City</label>
                                    <Field
                                        as="select"
                                        name="city"
                                        className="form-control"
                                        value={values.city || ''}
                                        style={{
                                            appearance: 'none',
                                            WebkitAppearance: 'none',
                                            MozAppearance: 'none',
                                            paddingRight: '30px',
                                            background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23999" d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center`,
                                            backgroundColor: '#fff',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            color: '#333'
                                        }}
                                    >
                                        <option value="">Select City</option>
                                        {cities.length > 0 &&
                                            cities.map((city) => (
                                                <option key={city.id} value={city.id}>
                                                    {city.city}
                                                </option>
                                            ))}
                                    </Field>
                                    <ErrorMessage name="city" component="span" className="error" />
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="form-label">Pincode</label>
                                    <Field
                                        name="pinCode"
                                        className="form-control"
                                        maxLength="6" 
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/\D/, ''); 
                                            if (e.target.value.length > 6) {
                                                e.target.value = e.target.value.slice(0, 6); 
                                            }
                                        }}
                                    />
                                    <ErrorMessage name="pinCode" component="span" className="error" />
                                </div>


                                <div className="form-group col-md-12">
                                    <CommonButton
                                        type="submit"
                                        buttonText="Submit"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Layout>
    );
};
export default AffiliateSignup;
