import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import icon1 from '../../assets/images/affiliateicon1.png';
import icon2 from '../../assets/images/affiliateicon2.png';
import icon3 from '../../assets/images/affiliateicon3.png';
import icon4 from '../../assets/images/affiliateline.png';
import affiliateimg from '../../assets/images/joinouraffiliatemarketing.png'
import { useNavigate } from 'react-router-dom';

const AffiliatePageSection2 = () => {
  const navigate = useNavigate()
  const handlechangeSignIn =()=>{
    navigate("/affiliate-signup")
  }
    return (
        <Container className="AffiliatePageSection2">
      <Row>
        <Col md={6}>
          <h1>Become an Affiliate in 3 Easy Steps</h1>

          {/* Step 1 */}
          <div className="affiliatesection2">
            <div>
              <img src={icon1} alt="Step 1" />
            </div>
            <div className="affiliatesection2-div-2">
              <span className="affiliatesection2-span1">Commission Range</span>
              <p className="affiliatesection2-span2">
                Signing up takes only a few minutes and is completely free
              </p>
            </div>
          </div>

          <div className="affiliatelinemain line-spacing">
            <div className="affiliateline"></div>
          </div>

          {/* Step 2 */}
          <div className="affiliatesection2 step">
            <div>
              <img src={icon2} alt="Step 2" />
            </div>
            <div className="affiliatesection2-div-2">
              <span className="affiliatesection2-span1">Commission Range</span>
              <p className="affiliatesection2-span2">
                Signing up takes only a few minutes and is completely free
              </p>
            </div>
          </div>

          <div className="affiliatelinemain ">
            <div className="affiliatelinespacing"></div>
          </div>

          {/* Step 3 */}
          <div className="affiliatesection2 step3">
            <div>
              <img src={icon3} alt="Step 3" />
            </div>
            <div className="affiliatesection2-div-2">
              <span className="affiliatesection2-span1">Commission Range</span>
              <p className="affiliatesection2-span2">
                Signing up takes only a few minutes and is completely free
              </p>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Container className='ouraffiliateimg'>
                <img src={affiliateimg} alt="" 
                style={{cursor: 'pointer' }}
                onClick={handlechangeSignIn}
                />
          </Container>       
        </Col>
      </Row>
    </Container>
    );
};
export default AffiliatePageSection2;