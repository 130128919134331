import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import api, { BASE_URL } from '../../api/api';
import { notification } from 'antd';
import { BsBank2 } from "react-icons/bs";


const AffiliateBankDetails = ({ affiliateId }) => {
    
    const [paymentDetails, setPaymentDetails] = useState({});
    const [bankStatus, setBankStatus] = useState(null);

    useEffect(() => {
        api.get(`/affiliate/getAffiliatePayment/${affiliateId}`)
            .then((res) => {
                setPaymentDetails(res.data.data);
                setBankStatus(res.data.data.bank_verify);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [affiliateId]);

    const isImage = (fileName) => {
        return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);
    };

    return (
        <div className="login-container">
            <Formik
                initialValues={{
                    bank_name: bankStatus === 2 ? paymentDetails.bank_name : '',
                    bankac: bankStatus === 2 ? paymentDetails.bankac : '',
                    bankac_reenter: '',
                    ifsc: bankStatus === 2 ? paymentDetails.ifsc : '',
                    bank_proof: null,
                }}
                enableReinitialize={true}
                validate={values => {
                    const errors = {};
                    if (!values.bank_name) errors.bank_name = 'Required';
                    if (!values.bankac) errors.bankac = 'Required';
                    else if (values.bankac.length !== 16) errors.bankac = 'Account number must be 16 digits long.';
                    if (!values.bankac_reenter) errors.bankac_reenter = 'Required';
                    else if (values.bankac_reenter !== values.bankac) errors.bankac_reenter = 'Account numbers do not match.';
                    if (!values.ifsc) errors.ifsc = 'Required';
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        const formData = new FormData();
                        formData.append("bank_name", values.bank_name);
                        formData.append("bankac", values.bankac);
                        formData.append("ifsc", values.ifsc);
                        if (values.bank_proof) {
                            formData.append("bank_proof", values.bank_proof);
                        }

                        const response = await api.put(`/affiliate/bank-info/${affiliateId}`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        });

                        notification.success({

                            message: response.data.message || 'Bank details submitted successfully!',
                          
                        });

                    } catch (error) {
                        const errorMessage = error.response?.data?.message || "An error occurred while submitting the form. Please try again.";
                        notification.error({
                            message: errorMessage,
                        });
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <form className="login-form" onSubmit={handleSubmit}>
                        {bankStatus === 2 ? (
                            <div>
                                <input type="text" name="bank_name" value={values.bank_name} readOnly />
                                <input type="text" name="bankac" value={values.bankac} readOnly />
                                <input type="text" name="ifsc" value={values.ifsc} readOnly />

                                {paymentDetails.bank_proof && (
                                    <div style={{textAlign:"center"}}>
                                        <h4>Document</h4>
                                        {isImage(paymentDetails.bank_proof) ? (
                                            <img
                                                src={`${BASE_URL}/upload/affiliate/bank/${paymentDetails.bank_proof}`} 
                                                alt="Bank Proof"
                                                style={{ width: '200px', height: 'auto' }}
                                            />
                                        ) : (
                                            <p>Uploaded file: {paymentDetails.bank_proof}</p>
                                        )}
                                    </div>
                                )}

                                <div className="pending-messageAffiliateDashboard">
                                    Pending bank verification <BsBank2 size={20}/>
                                </div>
                            </div>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    name="bank_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.bank_name}
                                    placeholder="Bank Account Name"
                                />
                                {errors.bank_name && touched.bank_name && <div className="error-message">{errors.bank_name}</div>}

                                <input
                                    type="text"
                                    name="bankac"
                                    onChange={e => {
                                        const value = e.target.value.replace(/\D/g, '').slice(0, 16);
                                        setFieldValue("bankac", value);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.bankac}
                                    placeholder="Account Number"
                                />
                                {errors.bankac && touched.bankac && <div className="error-message">{errors.bankac}</div>}

                                <input
                                    type="text"
                                    name="bankac_reenter"
                                    onChange={e => {
                                        const value = e.target.value.replace(/\D/g, '').slice(0, 16);
                                        setFieldValue("bankac_reenter", value);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.bankac_reenter}
                                    placeholder="Re-enter Account Number"
                                />
                                {errors.bankac_reenter && touched.bankac_reenter && <div className="error-message">{errors.bankac_reenter}</div>}

                                <input
                                    type="text"
                                    name="ifsc"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ifsc}
                                    placeholder="IFSC Code"
                                />
                                {errors.ifsc && touched.ifsc && <div className="error-message">{errors.ifsc}</div>}

                                <input
                                    type="file"
                                    name="bank_proof"
                                    onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        if (file) {
                                            setFieldValue("bank_proof", file);
                                        }
                                    }}
                                    onBlur={handleBlur}
                                />
                                {paymentDetails.bank_proof && (
                                    <div>
                                        <h4>Existing Bank Proof:</h4>
                                        {isImage(paymentDetails.bank_proof) ? (
                                            <img 
                                                src={`${BASE_URL}/upload/affiliate/bank/${paymentDetails.bank_proof}`} 
                                                alt="Existing Bank Proof"
                                                style={{ width: '200px', height: 'auto' }}
                                            />
                                        ) : (
                                            <p>Uploaded file: {paymentDetails.bank_proof}</p>
                                        )}
                                    </div>
                                )}
                                {errors.bank_proof && touched.bank_proof && <div className="error-message">{errors.bank_proof}</div>}

                                <button type="submit" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </>
                        )}
                        {errors.submit && <div className="error-message">{errors.submit}</div>}
                    </form>
                )}
            </Formik>
        </div>
    );
};
export default AffiliateBankDetails;
