import React, { useEffect, useState } from 'react';
import api from '../../api/api';

const ShowBankData = ({ affiliateId }) => {
    
    const [bankDetails, setBankDetails] = useState(null); 

    useEffect(() => {
        const fetchBankDetails = async () => {
            try {
                const response = await api.get(`/affiliate/getAffiliateById/${affiliateId}`); 

                setBankDetails(response.data.data || null); 
            } catch (error) {
                // console.error("Error fetching bank details:", error);
            }
        };

        if (affiliateId) {
            fetchBankDetails();
        }
    }, [affiliateId]);

   
    if (!bankDetails) {
        return <p>Loading...</p>; 
    }

    return (
        <>
            <table className="affiliate-table">
                <thead>
                    <tr>
                        <th>Bank Account Name</th>
                        <th>Account Number</th>
                        <th>IFSC Code</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{bankDetails.bank_name || 'N/A'}</td>
                        <td>{bankDetails.bankac || 'N/A'}</td>
                        <td>{bankDetails.ifsc || 'N/A'}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default ShowBankData;
