import React, { useState } from 'react';
import { notification, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import api from '../api/api';

const ResetPassword = ({ onSuccess }) => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [token, setToken] = useState('');
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const resetToken = searchParams.get('token');

    const handleRequestReset = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await api.post('/auth/request-password-reset', { email });
            notification.success({
                message: 'Success',
                description: response.data.message,
            });
            setStep(2);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.response?.data?.message || 'Failed to send reset link!',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!token) {
            notification.error({
                message: 'Error',
                description: 'Token is required!',
            });
            setLoading(false);
            return;
        }

        if (!newPassword) {
            notification.error({
                message: 'Error',
                description: 'New password is required!',
            });
            setLoading(false);
            return;
        }

        try {
            const tokenToSend = token || resetToken || '';
            const res = await api.post('/auth/reset-password', { resetToken: tokenToSend, newPassword });
            notification.success({
                message: 'Success',
                description: 'Your password has been reset successfully!',
            });
            onSuccess();
            navigate('/login');
            window.location.reload()
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.response?.data?.message || 'Failed to reset password!',
            });
        } finally {
            setLoading(false);
        }
    };

    // csss
    const styles = {
        container: {
            maxWidth: '400px',
            margin: '0 auto',
            padding: '20px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
        },
        title: {
            textAlign: 'center',
            marginBottom: '20px',
            fontSize: '17px',
            color: '#333',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        label: {
            marginBottom: '8px',
            fontWeight: 'bold',
            color: '#555',
        },
        input: {
            width: '100%',
            padding: '10px',
            marginBottom: '20px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
        },
        button: {
            padding: '10px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
        },
    };

    return (
        <Layout>
            <div style={styles.container}>
                <h2 style={styles.title}>{step === 1 ? 'Reset Password Request' : 'Set New Password'}</h2>
                <form onSubmit={step === 1 ? handleRequestReset : handleResetPassword} style={styles.form}>
                    {step === 1 ? (
                        <>
                            <div>
                                <label style={styles.label}>Email:</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={styles.input}
                                />
                            </div>
                            <button type="submit" style={styles.button} disabled={loading}>
                                {loading ? <Spin size="small" /> : 'Request Password Reset'}
                            </button>
                        </>
                    ) : (
                        <>
                            <div>
                                <label style={styles.label}>Enter Code:</label>
                                <input
                                    type="text"
                                    value={token}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        
                                        if (/^\d{0,4}$/.test(value)) {
                                            setToken(value);
                                        }
                                    }}
                                    required
                                    maxLength={4}
                                    style={styles.input}
                                />
                            </div>

                            <div>
                                <label style={styles.label}>New Password:</label>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    style={styles.input}
                                />
                            </div>
                            <button type="submit" style={styles.button} disabled={loading}>
                                {loading ? <Spin size="small" /> : 'Reset Password'}
                            </button>
                        </>
                    )}
                </form>
            </div>
        </Layout>
    );
};
export default ResetPassword;
