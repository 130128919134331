import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ProductInfo from "./ProductInfo";
import ProductImageSlider from "./ProductImageSlider";
import ColorSelector from "./ColorSelector";
import BundleSelector from "./BundleSelector";
import OrderSummary from "./OrderSummary";
import DefaultCourseBox from "./DefaultCourseBox";
import staticCourseDetails from "./courseData";
import initialImages from "./sliderImagesData";
import ClientForm from "./ClientForm";
import api from '../../../../src/api/api'
import Cookies from 'js-cookie'
import CountDown2 from "../../../pages/CountDown2";


const ProductDetails = ({ initialStep = 1 }) => {
  const [selectedColor, setSelectedColor] = useState("Gray");
  const [selectedColorid, setSelectedColorid] = useState("");
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [bookid, setBookId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { handleSubmit } = useForm();
  const [orderCheck, setOrder] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [books, setBooks] = useState([]);
  const [bookproduct, setBookProduct] = useState("");
  const [orderNull, setOrderNull] = useState([])

  const role = Cookies.get('role');
  const paymentstatus =Cookies.get("paymentstatus");

  useEffect(() => {
    api.get(`/books/getAllBooks`)
   
      .then((res) => {
        if (res.data && res.data.data) {
          setBooks(res.data.data);

          if (res.data.data.length > 0) {
            const defaultBook = res.data.data[0];
            setCurrentPrice(defaultBook.price);
            setDiscountedPrice(defaultBook.saleprice);
            setBookId(defaultBook.id || "null");
            setBookProduct(defaultBook.name || "Unknown Book");
          }
        }
      })
      .catch((err) => {
        // console.error('Error fetching books:', err);
      });
  }, []);

  useEffect(() => {
    if (staticCourseDetails.length > 0) {
      const defaultCourse = staticCourseDetails[0];
      setSelectedBundle(defaultCourse);
      if (defaultCourse.subCourses && defaultCourse.subCourses.length > 0) {
        const defaultSubCourse = defaultCourse.subCourses[0];
        setCurrentPrice(defaultSubCourse.price);
        setDiscountedPrice(defaultSubCourse.saleprice);
        setBookId(defaultSubCourse.id || "null");
        setBookProduct(defaultSubCourse.name || "Unknown Sub-Course");
      } else {
        setCurrentPrice(defaultCourse.price);
        setDiscountedPrice(defaultCourse.saleprice);
        setBookProduct(defaultCourse.courseName || "Unknown Course");
      }
    }
  }, []);

  useEffect(() => {
    if (role === 0) {
      const userorderid = Cookies.get('id');
  
      api.get(`/orders/getUserOrders/${userorderid}`)
        .then((res) => {
          const userOrder = res.data.data.find(order => order.user_id === userorderid);

          if (userOrder) {
            setOrder(userOrder.user_id);
            setOrderNull(userOrder);
            setPaymentStatus(userOrder.paymentstatus);
          } else {
            setOrder(null);
            setPaymentStatus(null);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [role]); 


  useEffect(() => {
    const userid = Cookies.get('id');
     const role =Cookies.get('rolee');
    // console.log("userid",userid);

    if ((userid === orderCheck) && (paymentStatus === 1)) {
      setCurrentStep(3);
    }else if(paymentstatus==='1'){
      setCurrentStep(3);
    }
     else if ((userid === orderCheck) && (paymentStatus === 0)) {
      setCurrentStep(2);
    } else if ((userid) && (!orderNull || Object.keys(orderNull).length === 0)) {
      setCurrentStep(2);
    } else if (role === '3') {
      setCurrentStep(4);
    } else {
      setCurrentStep(initialStep);
    }
  }, [initialStep, orderCheck, paymentStatus, role]);

  const handleColorSelect = (color) => {
    setSelectedColor(color.name);
    setSelectedColorid(color.id);
  };
  const handleBundleSelect = (bundleId) => {
    const selected = staticCourseDetails.find(
      (bundle) => bundle.id === parseInt(bundleId, 10)
    );
    if (selected) {
      setSelectedBundle(selected);
      const { id } = selected;

      const book = books.find(book => book.id === id);

      if (book) {
        setCurrentPrice(book.price);
        setDiscountedPrice(book.saleprice);
        setBookId(id || "null");
        setBookProduct(book.name || "Unknown Book");
      } else {
        setCurrentPrice("N/A");
        setDiscountedPrice("N/A");
        setBookProduct("Unknown Book");
      }
    } else {
      setSelectedBundle(null);
      setCurrentPrice("N/A");
      setDiscountedPrice("N/A");
      setBookProduct("Unknown Book");
    }
  };

  const handlePreOrder = (data) => {
    setCurrentStep(2);
  };

  const handleBack = () => setCurrentStep(1);

  const handleFormSuccess = () => {
    setCurrentStep(2);
  };

  const handlePaymentSuccess = () => {
    setIsModalVisible(false);
    setCurrentStep(1);
  };

  const renderOrderSummary = () => (
    <OrderSummary
      name="Solaris Book"
      images={
        initialImages[selectedColor] ? [initialImages[selectedColor][0]] : []
      }
      selectedColor={selectedColor}
      selectedBundle={
        selectedBundle ? selectedBundle.courseName || bookproduct : "Not Selected"
      }
      currentPrice={currentPrice}
      bookid={bookid}
      selectedColorid={selectedColorid}
      discountedPrice={discountedPrice}
      currentStep={currentStep}
      onPreOrder={handlePreOrder}
      handleSubmit={handleSubmit}
      onPaymentSuccess={handlePaymentSuccess}
      setIsModalVisible={setIsModalVisible}
    />
  );

  const images = initialImages[selectedColor] || [];

  return (
    <div className="product-details">
      <h1 className="product-details-center" >SolarisBook Pre Order with Digital Course
        <span > <CountDown2 /> </span>
      </h1>

      {
        currentStep === 4 ? (
          <div className="section step4">
            <h2 style={{ textAlign: 'center', width: "100%", padding: "20px" }}>You are affiliate Login. you will not able to order !</h2>
          </div>
        ) :
          currentStep === 3 ? (
            <div className="section step3">
              <h1> Each user can place a maximum of one order to ensure that< br /> all Indians have the opportunity to benefit from the scheme.</h1>
            </div>
          ) : currentStep === 2 ? (
            <div className="section step2">
              <div className="col-lg-6 col-md-6 col-12">
                <ProductImageSlider images={images} />
              </div>

              <div className="col-lg-6 col-md-6 col-12">
                <div className="padLeft">
                  <ProductInfo
                    description="Set your imagination free with the powerful Solarise book 14X OLED! Whether it's for creative work or gaming fun."
                    currentPrice="14,999"
                  />
                  <div className="colorQtWraps">
                    <ColorSelector
                      colors={[
                        { name: 'Gray', id: 1 },
                        { name: 'Black', id: 2 }
                      ]}
                      selectedColor={selectedColor}
                      onSelectColor={handleColorSelect}
                    />
                    <div className="qtSelectWrap">
                      <label htmlFor="quantity">Laptop Qty.</label>
                      <div className="qttBox">1</div>
                    </div>
                    <div className="qtSelectWrap">
                      <label htmlFor="quantity">Pay Later</label>
                      <p><del>₹60,000</del> ₹14999.00</p>
                    </div>
                  </div>

                  <BundleSelector
                    bundles={staticCourseDetails}
                    books={books}
                    selectedBundle={selectedBundle ? selectedBundle.id : ""}
                    onSelectBundle={handleBundleSelect}
                  />

                  <div className="produtcMainPrices">
                    <h2>
                      ₹{discountedPrice} <del> ₹{currentPrice}</del>
                    </h2>
                  </div>
                  <DefaultCourseBox />
                  {renderOrderSummary()}
                </div>
              </div>
            </div>
          ) : currentStep === 1 ? (
            <div className="section step1">
              <div className="col-mlg-10 col-md-10 col-12 mx-auto">
                <ClientForm
                  onSubmit={handlePreOrder}
                  onSuccess={handleFormSuccess}
                />
              </div>
            </div>
          ) : null}
    </div>
  );
};
export default ProductDetails;
