import React from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import Layout from "../../../components/Layout/Layout";
import CommonButton from "../../commonButton/CommonButton";
import { useNavigate } from "react-router-dom";

const PaymentSuccessModal = () => {
  const navigate = useNavigate();

  const handleChange =()=>{
    navigate("/my-account")
  }
  return (
    <Layout>
      <PageTitle
        gredTitle="Thank you"
      />
      <div className="paymentsuccessfull  boxWrpper">
        <div className="modal-content">
        <p>Thank you for your payment! Your transaction was successful, and your order has been confirmed. <br /> You will receive a confirmation email with the details shortly.</p>
        <p>If you have any questions, feel free to contact our support team.</p>
        
        <div className="paymentsuccessfull-btn">
        <CommonButton
         buttonText="My Orders"
         type="button"
         onClick={handleChange}
        />
        </div>
        
        </div>
      </div>
    </Layout>
  );
};

export default PaymentSuccessModal;
