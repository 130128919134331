import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; 

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [role, setRole] = useState(null); 
    const navigate = useNavigate();

    useEffect(() => {
        const userid = localStorage.getItem("userid");
        const userRole = Cookies.get('role'); 
        if (userid) {
            setIsAuthenticated(true);
            setRole(userRole); 
        } else {
            setIsAuthenticated(false);
            setRole(null);
        }
    }, []);

    const login = () => {
        setIsAuthenticated(true);
        const userRole = Cookies.get('role'); 
        setRole(userRole); 
        navigate('/dashboard'); 
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("userid");
        Cookies.remove('role'); 
        navigate('/');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, role, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
