import React, { useEffect, useRef, useState } from "react";
import api from '../../api/api'
import CommonButton from "../commonButton/CommonButton";

function CustomCarouselWrap({ cards }) {
  const [startIndex, setStartIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [bookAll, setBookAll] = useState([]);
  const cardRef = useRef(null);
  const cardCount = cards.length;

  useEffect(() => {
    const resizeHandler = () => {
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth + 15);
      }
    };

    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [cardCount]);

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(prevIndex => prevIndex - 1);
    }
  };
  const handleNext = () => {
    const limit = window.innerWidth <= 767 ? cardCount - 1
      : window.innerWidth <= 1024 ? cardCount - 2
        : cardCount - 3;

    if (startIndex < limit) {
      setStartIndex(prevIndex => prevIndex + 1);
    }
  };
  useEffect(() => {
     api.get('/books/getAllBooks')
    
      .then((res) => {
        setBookAll(res.data.data);
      });
  }, []);

  return (
    <div className="container">
      <div className="sectionTitleWrap">
        <h2>What end users will get with Pre-Booking Amount?</h2>
        <p>
          By booking, users will unlock lifetime access to over 25 digital
          courses covering diverse topics like programming, photography,
          business, and writing.
        </p>
      </div>
      <div className="slider-container">
        <div className="buttons">
          <button onClick={handlePrev} disabled={startIndex === 0}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464467C7.97631 0.269205 7.65973 0.269204 7.46447 0.464467C7.2692 0.659729 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5L11 4.5L11 3.5L1 3.5L1 4.5Z" fill="#919191" />
              </svg>
            </span>
          </button>
          <button onClick={handleNext} disabled={startIndex >= cardCount - 3}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464467C7.97631 0.269205 7.65973 0.269204 7.46447 0.464467C7.2692 0.659729 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5L11 4.5L11 3.5L1 3.5L1 4.5Z" fill="#919191" />
              </svg>
            </span>
          </button>
        </div>

        <div className="slider" style={{ transform: `translateX(-${startIndex * cardWidth}px)` }}>
          <div className="row flex-nowrap">
            {cards.map((card, index) => {
              
              const book = bookAll[index]

              return (
                <div className="col-md-4 resCard" key={index}>
                  <div
                    className="boxed"
                    ref={index === 0 ? cardRef : null}
                    style={{ backgroundImage: `url(${card.backgroundImg})` }}
                  >
                    <div className="book-image">
                      <img src={card.image} alt="" />
                    </div>

                    {book && (
                      <>
                        <div className="book-title">
                          <h2>{book.name}</h2>
                        </div>

                        <div className="hr-line">
                          <hr />
                        </div>

                        <div className="book-description">
                          <p>{book.description}</p>
                        </div>

                        <div className="price-book book-7courses">
                          <h2>₹ {book.saleprice} <del>₹{book.price}</del></h2>
                          <h4><img src={card.bookicon} alt="" /> {card.course}</h4>
                        </div>
                      </>
                    )}

                    <div className="book-btn">
                      <CommonButton
                        style={{ width: "10px" }}
                        buttonText="Order Now"
                        type="button"
                        modal="modal"
                        modalId="#staticBackdrop"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      
      <div className="bookbtncenter-home">
      <div className="buttons">
          <button onClick={handlePrev} disabled={startIndex === 0}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464467C7.97631 0.269205 7.65973 0.269204 7.46447 0.464467C7.2692 0.659729 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5L11 4.5L11 3.5L1 3.5L1 4.5Z" fill="#919191" />
              </svg>
            </span>
          </button>
          <button onClick={handleNext} disabled={startIndex >= cardCount - 1}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464467C7.97631 0.269205 7.65973 0.269204 7.46447 0.464467C7.2692 0.659729 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5L11 4.5L11 3.5L1 3.5L1 4.5Z" fill="#919191" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomCarouselWrap;
