import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from '../../../../src/api/api';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonButton from "../../commonButton/CommonButton";
import Cookies from 'js-cookie';
import { notification } from 'antd'

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Name should only contain letters.")
        .required("Name is required."),
    email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
    mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number.")
        .required("Mobile number is required."),
});

const ClientForm = ({ onSuccess }) => {
    const handleSubmit = async (values, { resetForm }) => {
        try {
            const formValues = {
                name: values.name,
                email: values.email,
                phone: values.mobileNumber,
                country: "1",
            };

            const response = await api.post(`/auth/createUser`, formValues);
   
            if (response.status === 200 && response.data.success) {
                const { id } = response.data.data;

                resetForm();
                if (onSuccess) {
                    onSuccess();
                    Cookies.set('id', response.data.data.userid, { secure: true, sameSite: 'Strict' });
                    Cookies.set('rolee', response.data.data.rolee, { secure: true, sameSite: 'Strict' });
                    notification.success({ message: response.data.message || "User created successfully and email sent!" });
                }
            } else {
                toast.error(response.data.message || "Failed to create user.");
            }
        } catch (error) {
            // console.error("Submission error:", error);
            if (error.response && error.response.data) {
                toast.error(error.response.data.message || "Something went wrong.");
            } else {
                toast.error("Something went wrong.");
            }
        }
    };

    return (
        <div className="form-container">
            <h2>Pre Order Registration</h2>
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    mobileNumber: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="row">
                        <div className="form-group col-md-12">
                            <label className="form-label">Full Name</label>
                            <Field name="name" className="form-control" />
                            <ErrorMessage name="name" component="span" className="error" />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Email ID</label>
                            <Field name="email" className="form-control" />
                            <ErrorMessage name="email" component="span" className="error" />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Mobile Number</label>
                            <Field
                                name="mobileNumber"
                                className="form-control"
                                onKeyPress={(event) => {
                                    const charCode = event.charCode;
                                    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
                                        event.preventDefault();
                                        toast.error("Mobile number should only contain digits.");
                                    }
                                }}
                                maxLength="10"
                            />
                            <ErrorMessage name="mobileNumber" component="span" className="error" />
                        </div>

                        <div className="form-group">
                            <Field
                                as="input"
                                name="country"
                                hidden
                                value="1"
                                className="form-control"
                                disabled
                            />
                        </div>
                        <div className="clientpagetermsandcondition">
                            <label>
                                <input type="checkbox" required />
                                By clicking Select Pre Order, you are agreeing to the
                                <a href="/terms-and-conditions">terms of use</a> and acknowledging the
                                <a href="/privacy-policy">privacy policy</a>.
                            </label>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Payment accepted via UPI</label>
                            <div className="payment-methods">
                                <img
                                    src="upi-payment.png"
                                    alt="UPI Payment Method"
                                    className="img-fluid"
                                    loading="lazy"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <CommonButton buttonText="Select Pre Order" type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
            <ToastContainer />
        </div>
    );
};
export default ClientForm;
