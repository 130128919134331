import React from "react";

const ColorSelector = ({ colors, selectedColor, onSelectColor }) => {
    return (
        <div className="color-selector">
            <label htmlFor="color-select" className="color-select">
                Laptop Color
            </label>
            <div className="radioWrap">
                {colors.map((color) => (
                    <label key={color.id} style={{ marginRight: "10px" }}>
                        <input
                            type="radio"
                            value={color.id}
                            checked={selectedColor === color.name} 
                            onChange={() => onSelectColor(color)}
                            style={{
                                appearance: "none",
                                width: "1.9rem",
                                height: "1.9rem",
                                border: `.4rem solid ${selectedColor === color.name ? "#b4b2b2" : "#fff"}`,
                                borderRadius: "50%",
                                position: "relative",
                                outline: "none",
                                cursor: "pointer",
                                backgroundColor: color.name.toLowerCase(),
                            }}
                        />
                        {color.name}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default ColorSelector;
