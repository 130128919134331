import React, { useState, useEffect } from 'react';
import api from '../api/api';
import Modal from 'react-modal';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminProductPage = () => {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingProductId, setEditingProductId] = useState(null);
    const [updatedProductData, setUpdatedProductData] = useState({ name: '', colour: '', description: '', price: '', saleprice: '' });
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [viewProductData, setViewProductData] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5); // You can adjust this number

    const fetchProducts = async () => {
        try {
            const { data } = await api.get(`/products/getAllProducts`);
            setProducts(data.data);
        } catch (error) {
            // console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const openEditProductModal = (product) => {
        setEditingProductId(product.id);
        setUpdatedProductData({
            name: product.name,
            colour: product.colour,
            description: product.description,
            price: product.price,
            saleprice: product.saleprice,
        });
        setIsProductModalOpen(true);
    };

    const closeProductModal = () => {
        setIsProductModalOpen(false);
        setEditingProductId(null);
        setUpdatedProductData({ name: '', colour: '', description: '', price: '', saleprice: '' });
    };

    const handleSearchProducts = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/products/searchProducts`, {
                    params: { query: searchQuery },
                });
                setProducts(data.data);
                setCurrentPage(1); // Reset to the first page on new search
            } catch (error) {
                // console.error('Error searching products:', error);
            }
        } else {
            fetchProducts();
        }
    };

    const handleEditProduct = async () => {
        try {
            await api.put(`/products/editProduct/${editingProductId}`, updatedProductData);
            setProducts((prev) =>
                prev.map((product) =>
                    product.id === editingProductId ? { ...product, ...updatedProductData } : product
                )
            );
            closeProductModal();
        } catch (error) {
            // console.error('Error editing product:', error);
        }
    };

    const handleDeleteProduct = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this product? Press Okay or Cancel');
        if (confirmDelete) {
            try {
                await api.delete(`/products/deleteProduct/${id}`);
                setProducts((prev) => prev.filter((product) => product.id !== id));
            } catch (error) {
                // console.error('Error deleting product:', error);
            }
        } else {
            alert('Product deletion canceled');
        }
    };

    const handleView = (productId) => {
        const productToView = products.find((product) => product.id === productId);
        setViewProductData(productToView);
        setIsViewModalOpen(true);
    };

    const closeViewModal = () => {
        setIsViewModalOpen(false);
        setViewProductData(null);
    };

    // Calculate which products to display
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Total number of pages
    const totalPages = Math.ceil(products.length / itemsPerPage);

    // Handlers for next and previous
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    return (
        <div className="dashboard-container Adminproductpage">
            <h1>Products Dashboard</h1>
            {/* Products Search Section */}
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search products"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearchProducts}>Search</button>
            </div>

            <h2>Products</h2>
            <table className="products-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Colour</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Sale Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentProducts.map((product) => (
                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.name}</td>
                            <td>{product.colour}</td>
                            <td>{product.description}</td>
                            <td>{product.price}</td>
                            <td>{product.saleprice}</td>
                            <td>
                                <FaEdit
                                    onClick={() => openEditProductModal(product)} style={{ fontSize: "18px", cursor: "pointer" }} title='Edit Products' />
                                <MdDelete
                                    onClick={() => handleDeleteProduct(product.id)} style={{ fontSize: "18px", cursor: "pointer" }} title='Delete Products' />
                                <GrView onClick={() => handleView(product.id)} style={{ fontSize: "18px", cursor: "pointer" }} title='View Products' />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePrevious} disabled={currentPage === 1}>
                    &laquo; Previous
                </button>

                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => paginate(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}

                <button onClick={handleNext} disabled={currentPage === totalPages}>
                    Next &raquo;
                </button>
            </div>

            {/* Edit Product Modal */}
            <Modal
                isOpen={isProductModalOpen}
                onRequestClose={closeProductModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="Edit Product"
            >
                <h2>Edit Product</h2>
                <form>
                    <label>
                        Name:
                        <input
                            type="text"
                            value={updatedProductData.name}
                            onChange={(e) =>
                                setUpdatedProductData({ ...updatedProductData, name: e.target.value })
                            }
                        />
                    </label>
                    <label>
                        Colour:
                        <input
                            type="text"
                            value={updatedProductData.colour}
                            onChange={(e) =>
                                setUpdatedProductData({ ...updatedProductData, colour: e.target.value })
                            }
                        />
                    </label>
                    <label>
                        Description:
                        <input
                            type="text"
                            value={updatedProductData.description}
                            onChange={(e) =>
                                setUpdatedProductData({ ...updatedProductData, description: e.target.value })
                            }
                        />
                    </label>
                    <label>
                        Price:
                        <input
                            type="text"
                            value={updatedProductData.price}
                            onChange={(e) =>
                                setUpdatedProductData({ ...updatedProductData, price: e.target.value })
                            }
                        />
                    </label>
                    <label>
                        Sale Price:
                        <input
                            type="text"
                            value={updatedProductData.saleprice}
                            onChange={(e) =>
                                setUpdatedProductData({ ...updatedProductData, saleprice: e.target.value })
                            }
                        />
                    </label>
                    <button type="button" onClick={handleEditProduct}>
                        Save Changes
                    </button>
                    <button type="button" onClick={closeProductModal}>
                        Cancel
                    </button>
                </form>
            </Modal>

            {/* View Product Modal */}
            <Modal
                isOpen={isViewModalOpen}
                onRequestClose={closeViewModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="View Product"
            >
                <h2>Product Details</h2>
                {viewProductData && (
                    <table className='adminuser-table'>
                        <tbody>
                            <tr>
                                <th>ID:</th>
                                <td>{viewProductData.id}</td>
                            </tr>
                            <tr>
                                <th>Name:</th>
                                <td>{viewProductData.name}</td>
                            </tr>
                            <tr>
                                <th>Colour:</th>
                                <td>{viewProductData.colour}</td>
                            </tr>
                            <tr>
                                <th>Description:</th>
                                <td>{viewProductData.description}</td>
                            </tr>
                            <tr>
                                <th>Price:</th>
                                <td>{viewProductData.price}</td>
                            </tr>
                            <tr>
                                <th>Sale Price:</th>
                                <td>{viewProductData.saleprice}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                <button onClick={closeViewModal}>Close</button>
            </Modal>

        </div>
    );
};

export default AdminProductPage;
