import React from "react";
import Layout from "../components/Layout/Layout";
import PageTitle from "../components/pageTitle/PageTitle";
import PragraphBox from "../components/innerpagecomponents/PragraphBox";
import { Link } from "react-router-dom";
import { Toaster, toast } from 'react-hot-toast';
import useSaleAlert from '../pages/ShowNotification';


function Shipping() {
    const { saleAlert, loading } = useSaleAlert(); 
    return (
        <Layout>
            <PageTitle gredTitle="Shipping" title="Policy" />
            <div className="boxWrpper">
                <PragraphBox
                    contentText="Digital Course Delivery:"
                    para={`Upon successful payment for any digital course, you will receive an email with a download link. This link will also be accessible via the "My Orders" section on your account. The email with the download link will be sent immediately after the completion of your purchase. Please note that the download link is only valid for 30 days, during which you must download the content to your computer or device. Once downloaded, the digital course content remains available for your personal use indefinitely..`}
                />

                <PragraphBox
                    contentText="Solaris Book Laptop Shipping:"
                    para={`Currently, we are not charging any upfront payments for the Solaris Book Laptop as it is still in development under the Make in India initiative. Customers who show interest by pre-booking 
                        will be notified once the laptop production is complete, following all required
                         manufacturing certifications and clearances. <br> <br> Once production is finalized, the pricing of the Solaris Book Laptop may vary due to market conditions. Customers who have expressed interest will receive a confirmation link via email, allowing them to proceed with the final purchase and payment. After completing the payment, the Solaris Book Laptop will be shipped to the customer’s registered address within 7 working days. Delivery timelines may vary depending on production, logistical factors, and external clearances.`}
                />

                <PragraphBox
                    contentText="Solaris Book Laptop Shipping Costs & Delivery Time:"
                    para={`Shipping costs for the Solaris Book Laptop will be communicated during the final purchase stage. Delivery times may vary based on the customer’s location and other external factors, but we strive to ensure prompt delivery once the product is ready and shipped to the customer’s registered address within 7 working days.`}
                />

                <PragraphBox
                    contentText="Solaris Book Laptop Tracking Orders:"
                    para={`After shipping, customers will receive an email containing their tracking 
                        information, allowing them to monitor the progress of the delivery. Please contact our customer support 
                        team if you have any questions regarding the shipment status.`}
                />

            </div>
            <Toaster position="bottom-left" reverseOrder={false} />   
        </Layout>
    );
}

export default Shipping;