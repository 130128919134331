import React, { useEffect, useState } from 'react';
import api from '../api/api';

const AdminAffiliateSlab = () => {
  const [slab, setSlab] = useState([]);

  useEffect(() => {
    api.get("/affiliate/calculateCommissionSlabForAllAffiliates")
      .then((res) => {
        setSlab(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="dashboard-container">
        <h2>Affiliate Slab</h2>
        <table className="affiliates-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>CurrentSlab</th>
              <th>TotalUnpaidCommission</th>
              <th>RemainingToNextSlab</th>
              <th>TotalPaidAmount</th>
            </tr>
          </thead>
          <tbody>
            {slab.length > 0 &&
              slab.map((elem) => (
                <tr key={elem.affiliateId}>
                  <td>{elem.affiliateId}</td>
                  <td>{elem.name}</td>
                  <td>{elem.email}</td>
                  <td>{elem.currentSlab}</td>
                  <td>{elem.totalUnpaidCommission}</td>
                  <td>{elem.remainingToNextSlab}</td>
                  <td>{elem.totalPaidAmount}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AdminAffiliateSlab;
