import React, { useState, useRef } from 'react';
import { Button, Modal, Space, notification } from 'antd';
import { Formik } from 'formik';
import api from '../api/api';

const PaymentUpdated = ({ selectedPanding }) => {
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    selectedCommissionIds: '',
    paymentReference: '',
    paymentMode: '',
    paymentDate: '',
  });

  const formikRef = useRef(null); 

  const showModal = () => {
    const ids = Array.from(selectedPanding).join(', ');
    setInitialValues({
      selectedCommissionIds: ids,
      paymentReference: '',
      paymentMode: '',
      paymentDate: '',
    });
    setOpen(true);
  };

  const handleCancel = () => {
    if (formikRef.current) {
      window.location.reload('');
    }
    setOpen(false);
  };

  const validate = values => {
    const errors = {};
    if (!values.selectedCommissionIds) errors.selectedCommissionIds = 'Required';
    if (!values.paymentReference) errors.paymentReference = 'Required';
    if (!values.paymentMode) errors.paymentMode = 'Required';
    if (!values.paymentDate) errors.paymentDate = 'Required';
    return errors;
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const payload = {
      selectedCommissionIds: values.selectedCommissionIds.split(', ').map(Number),
      paymentReference: values.paymentReference,
      paymentMode: values.paymentMode,
      paymentDate: values.paymentDate,
    };

    api.post('/affiliate/payments', payload)
      .then(() => {
        notification.success({ message: 'Payment submitted successfully' });
        resetForm();
        setSubmitting(false);
        setOpen(false); 
      })
      .catch(error => {
        notification.error({
          message: 'Error submitting payment',
          description: error.response ? error.response.data : 'An unknown error occurred',
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <Space>
        <Button type="primary" onClick={showModal}>
          Process
        </Button>
      </Space>
      <Modal
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <h2 style={{ textAlign: "center" }}>Add Payment</h2>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <h3> Selected ID: {values.selectedCommissionIds} </h3>
                <input
                  hidden
                  type="text"
                  name="selectedCommissionIds"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.selectedCommissionIds}
                  placeholder="Selected Commission IDs"
                />
                {errors.selectedCommissionIds && touched.selectedCommissionIds && (
                  <div style={{ color: 'red' }}>{errors.selectedCommissionIds}</div>
                )}
              </div>

              <div>
                <input
                  className='selectedCommissionIds'
                  type="text"
                  name="paymentReference"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.paymentReference}
                  placeholder="Payment Reference"
                />
                {errors.paymentReference && touched.paymentReference && (
                  <div style={{ color: 'red' }}>{errors.paymentReference}</div>
                )}
              </div>

              <div>
                <select
                  className='selectedCommissionIds'
                  name="paymentMode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.paymentMode}
                >
                  <option value="">Select Payment Mode</option>
                  <option value="Online">Online</option>
                  <option value="NFTE">NFTE</option>
                </select>
                {errors.paymentMode && touched.paymentMode && (
                  <div style={{ color: 'red' }}>{errors.paymentMode}</div>
                )}
              </div>

              <div>
                <input
                  className='selectedCommissionIds'
                  type="date"
                  name="paymentDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.paymentDate}
                />
                {errors.paymentDate && touched.paymentDate && (
                  <div style={{ color: 'red' }}>{errors.paymentDate}</div>
                )}
              </div>

              <button
                className='paymentButtonAdded'
                type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default PaymentUpdated;
