import React, { useEffect, useState } from 'react';
import api from '../api/api';
import Modal from 'react-modal';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";


const AdminBookPage = () => {
    const [books, setBooks] = useState([]);
    const [editingBookId, setEditingBookId] = useState(null);
    const [updatedBookData, setUpdatedBookData] = useState({
        name: '',
        description: '',
        link: '',
        price: '',
        saleprice: '',
        createdAt: '',
        updatedAt: '',
    });
    const [isOpenBookModal, setIsOpenBookModal] = useState(false);
    const [isOpenViewModal, setIsOpenViewModal] = useState(false);
    const [viewBookData, setViewBookData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch books
    const fetchBooks = async () => {
        try {
            const { data } = await api.get('/books/getAllBooks');
            setBooks(data.data);
        } catch (error) {
            // console.error("Error while getting Books", error);
        }
    };

    useEffect(() => {
        fetchBooks();
    }, []);

    const openEditBookModal = (book) => {
        setEditingBookId(book.id);
        setUpdatedBookData({ ...book });
        setIsOpenBookModal(true);
    };

    const closeBookModal = () => {
        setIsOpenBookModal(false);
        setEditingBookId(null);
        setUpdatedBookData({
            name: '',
            description: '',
            link: '',
            price: '',
            saleprice: '',
            createdAt: '',
            updatedAt: '',
        });
    };

    const openViewBookModal = (book) => {
        setViewBookData(book);
        setIsOpenViewModal(true);
    };

    const closeViewBookModal = () => {
        setIsOpenViewModal(false);
        setViewBookData(null);
    };
    
    const handleEditBook = async () => {
        try {
            await api.put(`/books/editBook/${editingBookId}`, updatedBookData);
            setBooks(prev =>
                prev.map(book =>
                    book.id === editingBookId ? { ...book, ...updatedBookData } : book
                )
            );
            closeBookModal();
        } catch (error) {
            // console.error("Error while editing book", error);
        }
    };

    const handleDeleteBook = async (bookId) => {
        const confirmDelete = window.confirm("Would you like to delete this book? Press Okay or Cancel");
        if (confirmDelete) {
            try {
                await api.delete(`/books/deleteBook/${bookId}`);
                setBooks(prev => prev.filter(book => book.id !== bookId));
            } catch (error) {
                // console.error("Error while deleting book", error);
            }
        }
    };

    const handleSearch = async () => {
        if (!searchQuery) {
            fetchBooks();
            return;
        }
        try {
            const { data } = await api.get(`/books/searchBooks?query=${searchQuery}`);
            setBooks(data.data);
        } catch (error) {
            // console.error("Error while searching books", error);
        }
    };

    return (
        <div className='dashboard-container'>
            <h1>Book Dashboard</h1>
            <div className='search-container'>
                <input
                    type="text"
                    placeholder='Search Book'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            <h2>Books</h2>

            <table className='adminuser-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Link</th>
                        <th>Price</th>
                        <th>Sale Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {books.map((book) => (
                        <tr key={book.id}>
                            <td>{book.name}</td>
                            <td>{book.description}</td>
                            <td><a href={book.link} target="_blank" rel="noopener noreferrer">View</a></td>
                            <td>{book.price}</td>
                            <td>{book.saleprice}</td>
                            <td style={{width:"100px"}}>
                                <FaEdit onClick={() => openEditBookModal(book)} style={{ fontSize: "18px", cursor: 'pointer' }} title='Edit Book'/>
                                <MdDelete onClick={() => handleDeleteBook(book.id)} style={{ fontSize: "18px", cursor: 'pointer' }} title='Delete Book'/>
                                <GrView onClick={() => openViewBookModal(book)} style={{ fontSize: "18px", cursor: 'pointer' }} title='View Book'/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Edit Book Modal */}
            <Modal
                isOpen={isOpenBookModal}
                onRequestClose={closeBookModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="Edit Book"
            >
                <h2>Edit Book</h2>

                <form className="row g-3">
                    <div className="col-md-6">
                        <label htmlFor='name'>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='name'
                            value={updatedBookData.name}
                            onChange={(e) =>
                                setUpdatedBookData({ ...updatedBookData, name: e.target.value })
                            }
                            placeholder="Name"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='description'>Description:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='description'
                            value={updatedBookData.description}
                            onChange={(e) =>
                                setUpdatedBookData({ ...updatedBookData, description: e.target.value })
                            }
                            placeholder="Description"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='link'>Link:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='link'
                            value={updatedBookData.link}
                            onChange={(e) =>
                                setUpdatedBookData({ ...updatedBookData, link: e.target.value })
                            }
                            placeholder="Link"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='price'>Price:</label>
                        <input
                            type="number"
                            className="form-control"
                            id='price'
                            value={updatedBookData.price}
                            onChange={(e) =>
                                setUpdatedBookData({ ...updatedBookData, price: e.target.value })
                            }
                            placeholder="Price"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='saleprice'>Sale Price:</label>
                        <input
                            type="number"
                            className="form-control"
                            id='saleprice'
                            value={updatedBookData.saleprice}
                            onChange={(e) =>
                                setUpdatedBookData({ ...updatedBookData, saleprice: e.target.value })
                            }
                            placeholder="Sale Price"
                        />
                    </div>

                </form>
                
                <div className="col-md-6">
                        <button type="button"
                         className="btn btn-primary" onClick={handleEditBook}>Save Changes</button>
                        <button type="button" className="btn btn-secondary" onClick={closeBookModal}>Cancel</button>
                    </div>
            </Modal>
            
            {/* View Book Modal */}
            <Modal
                isOpen={isOpenViewModal}
                onRequestClose={closeViewBookModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="View Book"
            >
                <h2>Book Details</h2>
                {viewBookData && (
                    <table className='adminuser-table'>
                        <tbody>
                            <tr>
                                <th> Digital Course:</th>
                                <td>{viewBookData.name}</td>
                            </tr>
                            <tr>
                                <th>Description:</th>
                                <td>{viewBookData.description}</td>
                            </tr>
                            <tr>
                                <th>Link:</th>
                                <td><a href={viewBookData.link} target="_blank" rel="noopener noreferrer">View Link</a></td>
                            </tr>
                            <tr>
                                <th>Total Price:</th>
                                <td>{viewBookData.price}</td>
                            </tr>
                            <tr>
                                <th>Discounted Price:</th>
                                <td>{viewBookData.saleprice}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                <button onClick={closeViewBookModal}>Close</button>
            </Modal>
        </div>
    );
};
export default AdminBookPage;
