import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import PaymentUpdated from './PaymentUpdated';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { Button, Modal } from 'antd';
import { Formik } from 'formik';


const AffiliateDetailsPage = () => {
    const [affiliates, setAffiliates] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingAffiliate, setEditingAffiliate] = useState(null);
    const [viewingAffiliate, setViewingAffiliate] = useState(null);
    const [updatedData, setUpdatedData] = useState({ name: '', email: '', phone: '', address: '' });
    const [commissionSlab, setCommissionSlab] = useState([]);
    const [isShowDetails, setIsShowDetails] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isShowPaid, setIsShowPaid] = useState(false);
    const [isShowPending, setIsShowPending] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [affiliateID, setAffiliateID] = useState(null);
    const [paidTransaction, setPaidTransaction] = useState([]);
    const [pendingTransaction, setPendingTransaction] = useState([]);
    const [selectedAffiliates, setSelectedAffiliates] = useState(new Set());

    const [selectedPanding, setSelectedPanding] = useState(new Set());

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isModalOpenBank, setIsModalOpenBank] = useState(false);
    const [affiliateActive, setAffiliateActive] = useState(null);



    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        fetchAffiliates(currentPage);
        fetchCommissionSlab();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        const fetchPaidTransaction = async () => {
            if (affiliateID) {
                try {
                    const response = await api.get(`affiliate/transactions/paid/${affiliateID}`);
                    setPaidTransaction(response.data.data);
                    setIsShowPaid(false);
                } catch (error) {
                    // console.error('Error fetching paid transactions:', error);
                }
            }
        };
        fetchPaidTransaction();
    }, [affiliateID]);

    useEffect(() => {
        const fetchPendingTransaction = async () => {
            if (affiliateID) {
                try {
                    const response = await api.get(`affiliate/transactions/pending/${affiliateID}`);
                    setPendingTransaction(response.data.data);
                    setIsShowPending(true);
                    setIsShowDetails(false);
                } catch (error) {
                    // console.error('Error fetching pending transactions:', error);
                }
            }
        };
        fetchPendingTransaction();
    }, [affiliateID]);

    const fetchAffiliates = async (page = 1) => {
        try {
            const { data } = await api.get(`/affiliate/getAllAffiliates`, { params: { page, limit: itemsPerPage } });
            if (data && data.data) {
                setAffiliates(data.data || []);
                setCurrentPage(data.data.currentPage);
                setTotalPages(data.data.totalPages);
            }
        } catch (error) {
            // console.error('Error fetching affiliates:', error);
        }
    };

    const fetchCommissionSlab = async () => {
        try {
            const { data } = await api.get(`/affiliate/calculateCommissionSlabForAllAffiliates`);
            if (data && data.data) {
                setCommissionSlab(data.data || []);
            }
        } catch (error) {
            // console.error('Error fetching commission slab:', error);
        }
    };

    const getAffiliateSlab = (affiliateId) => {
        return commissionSlab.find(slab => slab.affiliateId === affiliateId);
    };

    const handleSearch = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/affiliate/searchAffiliates`, { params: { query: searchQuery } });
                if (data && data.data) {
                    setAffiliates(data.data || []);
                    setTotalPages(1);
                }
            } catch (error) {
                // console.error('Error searching affiliates:', error);
            }
        } else {
            fetchAffiliates(currentPage);
        }
    };

    const handleEdit = async () => {
        try {
            await api.put(`/affiliate/editAffiliate/${editingAffiliate.id}`, updatedData);
            setAffiliates((prev) => prev.map((affiliate) =>
                affiliate.id === editingAffiliate.id ? { ...affiliate, ...updatedData } : affiliate
            ));
            setIsEditing(false);
        } catch (error) {
            // console.error('Error editing affiliate:', error);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Would you like to delete? Press Okay or Cancel");
        if (confirmDelete) {
            try {
                await api.delete(`/affiliate/deleteAffiliate/${id}`);
                setAffiliates((prev) => prev.filter((affiliate) => affiliate.id !== id));
            } catch (error) {
                // console.error('Error deleting affiliate:', error);
            }
        }
    };
   

    const handleView = (affiliate) => {
        setAffiliateActive(affiliate.id)
        setViewingAffiliate(affiliate);
        setIsShowDetails(true);
        const slabData = commissionSlab.find(slab => slab.affiliateId === affiliate);

        setUpdatedData({
            currentSlab: slabData ? slabData.currentSlab : 'N/A',
            totalUnpaidCommission: slabData ? slabData.totalUnpaidCommission : '0'
        });
    };

    const handleEditAffiliate = (affiliate) => {
        setEditingAffiliate(affiliate);
        setIsEditing(true);
        setUpdatedData({
            name: affiliate.name,
            email: affiliate.email,
            phone: affiliate.phone,
            address: affiliate.address,
            state: affiliate.state,
            city: affiliate.city,
            pinCode: affiliate.pinCode,
            country: affiliate.country,
            discount: affiliate.discount,
            commissionvalue: affiliate.commissionvalue,
            slab: affiliate.slab,
            code: affiliate.code,
            ip: affiliate.ip,
            bank_name: affiliate.bank_name,
            bankac: affiliate.bankac,
            ifsc: affiliate.ifsc,
            bank_proof: affiliate.bank_proof,
            bank_verify: affiliate.bank_verify,
            status: affiliate.status,
            createdAt: affiliate.createdAt,
            updatedAt: affiliate.updatedAt,
        });
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditingAffiliate(null);
    };

    const handleChangePage = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleSelectAffiliate = (id) => {
        const updatedSelection = new Set(selectedAffiliates);
        if (updatedSelection.has(id)) {
            updatedSelection.delete(id);
        } else {
            updatedSelection.add(id);
        }
        setSelectedAffiliates(updatedSelection);
    };

    const handleSelectAll = (e) => {
        const checked = e.target.checked;
        if (checked) {
            const allIds = affiliates.map(affiliate => affiliate.id);
            setSelectedAffiliates(new Set(allIds));
        } else {
            setSelectedAffiliates(new Set());
        }
    };

    // payment updated
    const handleSelectePanding = (Id) => {
        const updatedSelection = new Set(selectedPanding);
        if (updatedSelection.has(Id)) {
            updatedSelection.delete(Id);
        } else {
            updatedSelection.add(Id);
        }
        setSelectedPanding(updatedSelection);
    };

    const handleSelectAllPanding = (e) => {
        const checked = e.target.checked;
        if (checked) {
            const allIds = pendingTransaction.map(transaction => transaction.Id);
            setSelectedPanding(new Set(allIds));
        } else {
            setSelectedPanding(new Set());
        }
    };

    const handleProcessSelected = () => {
        const selectedIds = Array.from(selectedPanding);
        if (selectedIds.length > 0) {
        } else {
            // console.log("No transactions selected");
        }
    };
    //end updated 
    const handleDeleteSelected = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete the selected affiliates? Press Okay or Cancel");
        if (confirmDelete && selectedAffiliates.size > 0) {
            try {
                const idsToDelete = Array.from(selectedAffiliates);
                await Promise.all(idsToDelete.map(id => api.delete(`/affiliate/deleteAffiliate/${id}`)));
                setAffiliates(prev => prev.filter(affiliate => !selectedAffiliates.has(affiliate.id)));
                setSelectedAffiliates(new Set());
            } catch (error) {
                // console.error('Error deleting selected affiliates:', error);
            }
        }
    };
    // show modal
    const handleProcessSelectedBoth = () => {
        handleOpenModal();
        handleProcessSelected();

    }
    const showModalActive = () => {
        setIsModalOpenBank(true);
    };

    const handleOk = () => {
        setIsModalOpenBank(false);
    };
    const handleCancel = () => {
        setIsModalOpenBank(false);
    };


    // // 
    // useEffect(()=>{
    //      api.put(`/affiliate/updateAffiliateStatus${affiliateActive}`)
    //      .then((res)=>{

    //      })
    // },[])
    return (
        <div className="dashboard-container">
            {!isShowDetails && !isEditing && !isShowPaid && !isShowPending ? (
                <>
                    <h1>Affiliate Dashboard</h1>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search affiliates"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button onClick={handleSearch}>Search</button>
                    </div>
                    <table className="affiliates-table">
                        <thead>
                            <tr>
                                <th><input type="checkbox" onChange={handleSelectAll} /></th>
                                <th>
                                    Name
                                </th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Bank Verify</th>
                                <th>Slab</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliates.map((affiliate) => (
                                <tr key={affiliate.id}>
                                    <td><input
                                        type="checkbox"
                                        checked={selectedAffiliates.has(affiliate.id)}
                                        onChange={() => handleSelectAffiliate(affiliate.id)}
                                    /></td>
                                    <td>
                                        {affiliate.name}
                                    </td>
                                    <td>{affiliate.email}</td>
                                    <td>{affiliate.phone}</td>
                                    <td>{affiliate.bank_verify}</td>
                                    <td>{getAffiliateSlab(affiliate.id)?.currentSlab || 'N/A'}</td>

                                    <td>
                                        {affiliate.status === 0 ? "No Active" : affiliate.status === 1 ? 'Active' : affiliate.status}
                                    </td>
                                    <td>
                                        <FaEdit
                                         onClick={() => handleEditAffiliate(affiliate)} className='editAffiliate-icon' title='Edit Affiliate Details' />
                                        <MdDelete onClick={() => handleDelete(affiliate.id)} className='editAffiliate-icon'   title='Delete Affiliate Details'/>
                                        <GrView onClick={() => handleView(affiliate)} className='editAffiliate-icon'  title='View Affiliate Details'/>
                                        <FaMoneyBillTransfer onClick={() => setAffiliateID(affiliate.id)} className='editAffiliate-icon' 
                                         title='Pending Transactions'
                                            />

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <button
                        className='checkboxdeletebtn'
                        onClick={handleDeleteSelected}
                    >
                        Delete Selected
                    </button>
                    <div className="pagination-controls">
                        <button onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                        <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='dropdowndashboard'>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                </>
            ) : isShowDetails && !isShowPaid && !isShowPending ? (
                <div className='dashboardAffiliateView'>
                    {viewingAffiliate && (
                        <>
                            <div className='AffiliateDetails-overfole'>
                                <h1>Affiliate Details</h1>
                                <div className="button-group" style={{ marginBottom: "20px" }}>
                                    <button
                                        className='ViewPaidTransaction_css'
                                        onClick={() => {
                                            setAffiliateID(viewingAffiliate.id);
                                            setIsShowPaid(true);
                                            setIsShowDetails(false);
                                        }}>
                                        View Paid Transactions
                                    </button>
                                    <button
                                        className='ViewPaidTransaction_css'
                                        onClick={() => {
                                            setAffiliateID(viewingAffiliate.id);
                                            setIsShowPending(true);
                                            setIsShowDetails(false);
                                        }}>
                                        View Pending Transactions
                                    </button>
                                    {/* Active Affiliate */}
                                    <button
                                        className='affiliateActivedashboard'
                                        onClick={showModalActive}
                                    > Active </button>
                                </div>
                                <table className='adminuser-table'>
                                    <tbody>
                                        <tr>
                                            <th>Name:</th>
                                            <td>{viewingAffiliate.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{viewingAffiliate.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone:</th>
                                            <td>{viewingAffiliate.phone}</td>
                                        </tr>
                                        <tr>
                                            <th>Address:</th>
                                            <td>{viewingAffiliate.address}</td>
                                        </tr>
                                        <tr>
                                            <th>Bank Name:</th>
                                            <td>{viewingAffiliate.bank_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Bank Verify:</th>
                                            <td>{viewingAffiliate.bank_verify}</td>
                                        </tr>
                                        <tr>
                                            <th>Bank Account:</th>
                                            <td>{viewingAffiliate.bankac}</td>
                                        </tr>
                                        <tr>
                                            <th>Code:</th>
                                            <td>{viewingAffiliate.code}</td>
                                        </tr>
                                        <tr>
                                            <th>Commission Value:</th>
                                            <td>{viewingAffiliate.commissionvalue}</td>
                                        </tr>
                                        <tr>
                                            <th>Discount:</th>
                                            <td>{viewingAffiliate.discount}</td>
                                        </tr>
                                        <tr>
                                            <th>IFSC:</th>
                                            <td>{viewingAffiliate.ifsc}</td>
                                        </tr>
                                        <tr>
                                            <th>Status:</th>
                                            <td>{viewingAffiliate.status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button
                                    className='dashboardButtonBack'
                                    onClick={() => setIsShowDetails(false)}>
                                    Back
                                </button>
                            </div>

                        </>
                    )}
                </div>
            ) : isShowPaid ? (
                <>
                    <div className='ViewPaidTrasactionTable'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order Email</th>
                                    <th>Amount</th>
                                    <th>Paid</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paidTransaction.length > 0 && paidTransaction.map((transaction) => (
                                    <tr key={transaction.id}>
                                        <td>{transaction.Order_Email}</td>
                                        <td>{transaction.Amount}</td>
                                        <td>{transaction.Paid}</td>
                                        <td>{transaction.updatedAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button className='dashboardButtonBack'
                        onClick={() => { setIsShowPaid(false); setIsShowDetails(true); }}>Back
                    </button>
                </>
            ) : isShowPending ? (
                <>
                    <div className='ViewPaidTrasactionTable'>
                        <h1>Pending Transactions</h1>
                        <table>
                            <thead>
                                <tr>
                                    <th> <input type="checkbox" onChange={handleSelectAllPanding} />  Order Email</th>
                                    <th>Amount</th>
                                    <th>Paid</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingTransaction.map((transaction) => (
                                    <tr key={transaction.id}>
                                        <td>  <input
                                            type="checkbox"
                                            checked={selectedPanding.has(transaction.Id)}
                                            onChange={() => handleSelectePanding(transaction.Id)}
                                        /> {transaction.Order_Email}</td>
                                        <td>{transaction.Amount}</td>
                                        <td>{transaction.Paid}</td>
                                        <td>{transaction.createdAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <PaymentUpdated selectedPanding={selectedPanding} onClick={handleProcessSelectedBoth} open={isModalOpen} onClose={handleCloseModal} />

                    <button className='dashboardButtonBack'
                        onClick={() => {
                            setIsShowPending(false);
                            setIsShowDetails(false);
                            setIsEditing(false);
                        }}>Back
                    </button>
                </>
            ) : isEditing && (
                <div className='dashboardAffiliateEditInner'>
                    <h2>Edit Affiliate</h2>

                    {/* Row for Name and Email */}
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id='name'
                                className="form-control"
                                value={updatedData.name}
                                onChange={(e) => setUpdatedData({ ...updatedData, name: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="text"
                                id='email'
                                className="form-control"
                                value={updatedData.email}
                                onChange={(e) => setUpdatedData({ ...updatedData, email: e.target.value })}
                            />
                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="code">Affiliate Code:</label>
                            <input
                                type="text"
                                id='code'
                                className="form-control"
                                value={updatedData.code}
                                onChange={(e) => setUpdatedData({ ...updatedData, code: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="ip">IP Address:</label>
                            <input
                                type="text"
                                id='ip'
                                className="form-control"
                                value={updatedData.ip}
                                onChange={(e) => setUpdatedData({ ...updatedData, ip: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="bank_name">Bank Name:</label>
                            <input
                                type="text"
                                id='bank_name'
                                className="form-control"
                                value={updatedData.bank_name}
                                onChange={(e) => setUpdatedData({ ...updatedData, bank_name: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="bankac">Bank Account:</label>
                            <input
                                type="text"
                                id='bankac'
                                className="form-control"
                                value={updatedData.bankac}
                                onChange={(e) => setUpdatedData({ ...updatedData, bankac: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="ifsc">IFSC Code:</label>
                            <input
                                type="text"
                                id='ifsc'
                                className="form-control"
                                value={updatedData.ifsc}
                                onChange={(e) => setUpdatedData({ ...updatedData, ifsc: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="bank_proof">Bank Proof:</label>
                            <input
                                type="text"
                                id='bank_proof'
                                className="form-control"
                                value={updatedData.bank_proof}
                                onChange={(e) => setUpdatedData({ ...updatedData, bank_proof: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="bank_verify">Bank Verification:</label>
                            <input
                                type="text"
                                id='bank_verify'
                                className="form-control"
                                value={updatedData.bank_verify}
                                onChange={(e) => setUpdatedData({ ...updatedData, bank_verify: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="status">Status:</label>
                            <input
                                type="text"
                                id='status'
                                className="form-control"
                                value={updatedData.status === 0 ? " Not Active" : updatedData.status === 1 ? 'Active' : updatedData.status}
                                onChange={(e) => setUpdatedData({ ...updatedData, status: e.target.value })}
                            />
                        </div>
                    </div>

                    {/* Row for Created At and Updated At */}
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="createdAt">Created At:</label>
                            <input
                                type="text"
                                id='createdAt'
                                className="form-control"
                                value={updatedData.createdAt}
                                onChange={(e) => setUpdatedData({ ...updatedData, createdAt: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="updatedAt">Updated At:</label>
                            <input
                                type="text"
                                id='updatedAt'
                                className="form-control"
                                value={updatedData.updatedAt}
                                onChange={(e) => setUpdatedData({ ...updatedData, updatedAt: e.target.value })}
                            />
                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="discount">Discount:</label>
                            <input
                                type="text"
                                id='discount'
                                className="form-control"
                                value={updatedData.discount}
                                onChange={(e) => setUpdatedData({ ...updatedData, discount: e.target.value })}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="commissionvalue">CommissionVal:</label>
                            <input
                                type="text"
                                id='commissionvalue'
                                className="form-control"
                                value={updatedData.commissionvalue}
                                onChange={(e) => setUpdatedData({ ...updatedData, commissionvalue: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="slab">Slab:</label>
                            <input
                                type="text"
                                id='slab'
                                className="form-control"
                                value={updatedData.slab}
                                onChange={(e) => setUpdatedData({ ...updatedData, slab: e.target.value })}
                            />
                        </div>
                    </div>

                    <button onClick={handleEdit} className='dashboardAffiliateEditInner_save btn btn-primary'>Save</button>
                    <button className='dashboardAffiliateEditInner_cancel btn btn-secondary' onClick={handleCancelEdit}>Cancel</button>
                </div>
            )}
            <Modal open={isModalOpenBank} onOk={handleOk} onCancel={handleCancel}
                footer={[
                    <Button
                       className="custom-cancel-button-activeAffiliate"
                     key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
            >
                <div className='activeAffiliate-css_main'>
                    <h1 style={{ textAlign: "center", paddingBottom: "20px" }}>Activate Affiliate </h1>
                    <Formik
                        initialValues={{ discount: '', commissionvalue: '', status: '1' }}
                        validate={values => {
                            const errors = {};
                            if (!values.discount) {
                                errors.discount = 'Discount is required';
                            } else if (isNaN(values.discount)) {
                                errors.discount = 'Discount must be a number';
                            }

                            if (!values.commissionvalue) {
                                errors.commissionvalue = 'Commission value is required';
                            } else if (isNaN(values.commissionvalue)) {
                                errors.commissionvalue = 'Commission value must be a number';
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            api.put(`/affiliate/updateAffiliateStatus/${affiliateActive}`, {
                                discount: values.discount,
                                commissionvalue: values.commissionvalue,
                                status: values.status,
                            })
                                .then(response => {
                                    alert('Affiliate status updated successfully!');
                                   
                                })
                                .catch(error => {
                                    alert('Error updating affiliate status.');
                                    // console.error(error);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="discount">Discount:</label>

                                    <input
                                        className='activeAffiliate-css'
                                        type="text"
                                        name="discount"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.discount}
                                    />
                                    {errors.discount && touched.discount && (
                                        <div style={{ color: 'red' }}>{errors.discount}</div>
                                    )}
                                </div>
                                <div>
                                    <label htmlFor="commissionvalue">CommiVal:</label>
                                    <input
                                        className='activeAffiliate-css'
                                        type="text"
                                        name="commissionvalue"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.commissionvalue}
                                    />
                                    {errors.commissionvalue && touched.commissionvalue && (
                                        <div style={{ color: 'red' }}>{errors.commissionvalue}</div>
                                    )}
                                </div>
                                <input

                                    type="hidden"
                                    name="status"
                                    value={values.status}
                                    onChange={handleChange}
                                />
                                <button type="submit" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </form>

                        )}

                    </Formik>

                </div>
            </Modal>
        </div>
    );
};
export default AffiliateDetailsPage;
