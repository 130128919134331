import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import pic1 from '../../assets/images/affiliatesec3.png'
import priceicon1 from '../../assets/images/priceicon1.png'

const AffiliatePageSection3 = () => {
    return ( 
    < > 
    { /* Desktop Version */ }
        <Container className='AffiliatePageSectionmain3' fluid>
                <Row>
                    <Col md={12} >
                        <div className="innwrWrap">
                            <div className='Affiliatesec3head'>
                                <h2>Commission Release Slabs</h2>
                                <p>Our Commission Payment structure ensures you receive your earnings in a structured manner.</p>
                                <div className='affliateSec3imgInner'>
                                    <img src={pic1} alt="Affiliate Commission Structure" />
                                </div>
                            </div>

                            <div className='affiliatesec3Box1'>
                                <div className='affiliatesec3Boxinnerbtn'> First Release </div>
                                <p>Once you reach this amount, you will receive your first payment.</p>
                            </div>

                            <div className='affiliatesec3Box-2'>
                                <div className='affiliatesec3Boxinnerbtn'> Second Release </div>
                                <p>Once you reach this amount, you will receive your second payment.</p>
                            </div>

                            <div className='affiliatesec3Box-3'>
                                <div className='affiliatesec3Boxinnerbtn'> Third Release </div>
                                <p>Once you reach this amount, you will receive your third payment.</p>
                            </div>

                            <div className='affiliatesec3Box-4'>
                                <div className='affiliatesec3Boxinnerbtn'> Fourth Release </div>
                                <p>Once you reach this amount, you will receive your fourth payment.</p>
                            </div>

                            <div className='affiliatesec3Box-5'>
                                <div className='affiliatesec3Boxinnerbtn'> Fifth Release </div>
                                <p>Once you reach this amount, you will receive your fifth payment.</p>
                            </div>

                            <div className='affiliatesec3Box-Subsequent'>
                                <div className='affiliatesec3Boxinner-Subsequentbtn'> Subsequent Release </div>
                                <p>Once you reach this amount, you will continue to receive subsequent payments.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            { /* Mobile Version */ }

        <Container className='affiliatesection3Mobile'>
                
                    <div className='Affiliatesec3headMobile'>
                        <h2>Commission Release Slabs</h2>
                        <p>Our Commission Payment structure ensures you receive your earnings in a structured manner.</p>

                        {/* First Release for Mobile */}
                        <div className='section3mobilecardmain'>
                            <div className='affiliatesec3Box1'>
                                <div className='affiliatesec3Boxinnerbtn'> First Release </div>
                                <p>Once you reach this amount, you will receive your first payment.</p>
                            </div>
                            <div className='affliateSec3imgprice'>
                                <img src={priceicon1} alt="First Release Icon" width='100px' height="100px" />
                            </div>
                        </div>

                        {/* Second Release for Mobile */}
                        <div className='section3mobilecardmain2'>

                            <div className='affliateSec3imgpric2'>
                                <img src={priceicon1} alt="Second Release Icon" width='100px' height="100px" />
                            </div>

                            <div className='affiliatesec3Box-2Mobile'>
                                <div className='affiliatesec3Boxinnerbtn'> Second Release </div>
                                <p>Once you reach this amount, you will receive your second payment.</p>
                            </div>
                        </div>
                        {/* Third Release for Mobile */}
                        <div className='section3mobilecardmain'>
                            <div className='affiliatesec3Box1'>
                                <div className='affiliatesec3Boxinnerbtn'> Third Release </div>
                                <p>Once you reach this amount, you will receive your first payment.</p>
                            </div>
                            <div className='affliateSec3imgprice'>
                                <img src={priceicon1} alt="First Release Icon" width='100px' height="100px" />
                            </div>
                        </div>
                        {/* Fourth Release for Mobile */}
                        <div className='section3mobilecardmain2'>

                            <div className='affliateSec3imgpric2'>
                                <img src={priceicon1} alt="Second Release Icon" width='100px' height="100px" />
                            </div>

                            <div className='affiliatesec3Box-2Mobile'>
                                <div className='affiliatesec3Boxinnerbtn'> Fourth Release </div>
                                <p>Once you reach this amount, you will receive your second payment.</p>
                            </div>
                        </div>
                        {/* Fifth Release for Mobile */}
                        <div className='section3mobilecardmain'>
                            <div className='affiliatesec3Box1'>
                                <div className='affiliatesec3Boxinnerbtn'> Fifth Release </div>
                                <p>Once you reach this amount, you will receive your first payment.</p>
                            </div>
                            <div className='affliateSec3imgprice'>
                                <img src={priceicon1} alt="First Release Icon" width='100px' height="100px" />
                            </div>
                        </div>

                         {/* Six Release for Mobile */}
                        <div className='section3mobilecardmain2'>

                            <div className='affliateSec3imgpric2'>
                                <img src={priceicon1} alt="Second Release Icon" width='100px' height="100px" />
                            </div>

                            <div className='affiliatesec3Box-2Mobile'>
                                <div className='affiliatesec3Boxinnerbtn'> Subsequent Release </div>
                                <p>Once you reach this amount, you will receive your second payment.</p>
                            </div>
                        </div>
                    </div>
            </Container> 
            </>
    )
}
export default AffiliatePageSection3