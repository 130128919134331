import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
const CountDown = () => {
  // Set initial time (5 hours, 37 minutes, and 60 seconds)
  const initialTime = {
    hours: 14,
    minutes: 37,
    seconds: 60,
  };

  const getTimeLeftFromCookie = () => {
    const savedTime = Cookies.get('countdownTime');
    if (savedTime) {
      return JSON.parse(savedTime);
    }
    return initialTime;
  };

  const [timeLeft, setTimeLeft] = useState(getTimeLeftFromCookie);


  useEffect(() => {
    const countdown = () => {
      setTimeLeft((prevTime) => {
        // Calculate new time
        let { hours, minutes, seconds } = prevTime;

        // Decrease seconds
        if (seconds > 0) {
          seconds--;
        } else {
          // If seconds are 0, reset to 59 and decrease minutes
          seconds = 59;
          if (minutes > 0) {
            minutes--;
          } else {
            // If minutes are 0, reset to 59 and decrease hours
            minutes = 59;
            if (hours > 0) {
              hours--;
            } else {
              // Countdown finished
              clearInterval(timer);
              Cookies.remove('countdownTime'); 
              return { hours: 0, minutes: 0, seconds: 0 };
            }
          }
        }
        Cookies.set('countdownTime', JSON.stringify({ hours, minutes, seconds }), { expires: 1});
        return { hours, minutes, seconds };
      });
    };

    const timer = setInterval(countdown, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className='timmermaincountdown'>
      <h4>Don't miss the <span style={{color:"#FFFF40"}}> 75% off  </span> sale!
   
        Ends in <span style={{color:"#FFFF40"}}> {timeLeft.hours.toString().padStart(2, '0')} Hours: {timeLeft.minutes.toString().padStart(2, '0')} Minutes: {timeLeft.seconds.toString().padStart(2, '0')} Seconds </span>
      </h4>
      {timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 && <p>The sale has ended!</p>}
    </div>
  );
};
export default CountDown;
