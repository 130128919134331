import React from 'react';
import Cookies from 'js-cookie';
import RedirectWithDelay from '../Auth/RedirectWithDelay';

const PrivateRoute = ({ children }) => {
  const userRole = Cookies.get('role'); 

  if (userRole === '3') {
    return children; 
  } else {
    return <RedirectWithDelay message="Illegal operation. Redirecting to the homepage..." to="/" />;
  }
};
export default PrivateRoute;
