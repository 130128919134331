import React from "react";
import CommonButton from "../commonButton/CommonButton";
import ImageSlider from "../ImageSlider/ImageSlider";
import pic1 from '../../images/gray/pic1.png'
import pic2 from '../../images/gray/pic2.png'
import pic3 from '../../images/gray/pic3.png'
import pic4 from '../../images/gray/pic4.png'
import pic5 from '../../images/gray/pic5.png'
import pic6 from '../../images/gray/pic6.png'
// black images
import img1 from '../../images/black/8_Black.png'

import img3 from '../../images/black/1_Black.png'
import img4 from '../../images/black/2_Blaack.png'
import img5 from '../../images/black/3_Black.png'
import img7 from '../../images/black/5_Black.png'
import img8 from '../../images/black/11_Black.png'
import img9 from '../../images/black/12_Black.png'

function ProductFeature() {
    const colorImages = {
        gray: [
            pic1,
            pic2,
            pic3,
            pic4,
            pic5,
            pic6
        ],
        black: [
            img1,

            img3,
            img5,
            img4,
            img7,
            img8,
            img9
        ],

    };
    return (
        <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <div className="leftBox">
            <h2>Future Laptop for Digital India</h2>
            <p>
              Set your imagination free with the powerful SOlarisBook 14X OLED!
              Whether it's for creative work or gaming fun, its 13th Gen Intel®
              Core™ processor and NVIDIA® GeForce RTX™ 3050 Laptop GPU1
              give you all the multitasking power you need.
            </p>
            <CommonButton 
            buttonText="Pre Order Now"
            type="button"
            modal="modal"
            modalId="#staticBackdrop"
            />
          </div>
        </div>
        
        <div className="col-md-12 col-lg-6">
          <ImageSlider colorImages={colorImages} />
        </div>
      </div>
    </div>
    );
}

export default ProductFeature;