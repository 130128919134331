import React from "react";
import Layout from "../components/Layout/Layout";
import PageTitle from "../components/pageTitle/PageTitle";
import PragraphBox from "../components/innerpagecomponents/PragraphBox";
import { Toaster, toast } from 'react-hot-toast';
import useSaleAlert from '../pages/ShowNotification';

function TermsConditions() {
  const { saleAlert, loading } = useSaleAlert(); 
  return (
    <Layout>
      <PageTitle gredTitle="Terms &" title="Conditions" />
      <div className="boxWrpper">
        <PragraphBox
          contentText="Ownership:"
          para={`By utilizing our website to purchase a digital course, you acknowledge that all intellectual property rights related to Solaris Comtech products, including but not limited to design intricacies, trademarks, and patents, are the exclusive property of Solaris Comtech, a company manufacturing under the Make in India initiative.
.`}
        />
        <PragraphBox
          contentText="Agreement to Terms Of Use:"
          para={`By purchasing the digital course through our website, you agree to abide by these terms and conditions. Any violation may lead to the termination of your access to the website and potential legal consequences.
.`}
        />
        <PragraphBox
          contentText="Copyright and Other Intellectual Property:"
          para={`The entirety of the content featured on this website, including but not confined to textual descriptions, graphical elements, logos, images, and software applications, is entrenched within the purview of copyright and other intellectual property statutes. As a user, you explicitly agree not to replicate, distribute, or manipulate any of the content without prior express written consent from Solaris Comtech, the authorized entity.
Please note that all website images and visuals related to the Digital Course and Solaris Book Laptop are provided for presentational purposes only. The actual products may vary in appearance, features, or specifications at the time of delivery. Solaris Comtech reserves the right to make modifications to product designs and features without prior notice.
.`}
        />
        <PragraphBox
          contentText="Your Use of the Website:"
          para={`Your use of the website must comply with all applicable laws and regulations. Unauthorized use of any part of the website, including its resources, may result in the termination of your access and could lead to legal action.
`}
        />
        <PragraphBox
          contentText="Personal Information:"
          para={`By purchasing the digital course, you agree to our collection, use, and disclosure of your personal information as described in our Privacy Policy. This information may include, but is not limited to, your name, contact details, and payment information. The collection of this information is intended to ensure secure and reliable transactions on our website.`}
        />
        <PragraphBox
          contentText="Accounts, Passwords, and Security:"
          para={`You are responsible for maintaining the confidentiality of your account credentials and passwords. Any instances of unauthorized access or suspicious activity must be reported to us immediately. Failure to secure your account may result in unauthorized access and potential data breaches.`}
        />
        <PragraphBox
          contentText="User-Provided Content::"
          para={`Content provided by users on this platform (e.g., reviews or comments) remains the user’s property. However, by submitting such content, you grant Solaris Comtech a non-exclusive, royalty-free license to use it as necessary for website operations.`}
        />
        <PragraphBox
          contentText="Digital Course Purchase:"
          para={`The payment made on our website is solely for accessing a digital course, provided as a fair value proposition relative to similar market offerings. This payment is exclusively for the course itself and does not constitute a pre-order, reservation, or guarantee of access to any other product, including the Solaris Book Laptop or any other product from Solaris Comtech. The digital course is an independent product offered by its respective authors, not owned, authored, or claimed by Solaris Comtech, which serves only as a facilitator by providing a link to access this content.
            <br/>  <br/>
            The digital course belongs entirely to its respective authors, and any inquiries should be directed to them. Users are encouraged to visit the author's website for further information or to purchase additional content. Solaris Comtech is not claiming ownership of or responsibility for the course material. If users prefer, they may contact the respective author directly to purchase content from the original source.
            <br/>  <br/>
            Our service merely provides convenient access to this course through a link for download. The fee charged by Solaris Comtech covers only the availability of this link for your convenience.
            `}
        />
        <PragraphBox
          contentText="No Guarantee:"
          para={`The purchase of the digital course does not imply or guarantee that you will be given the opportunity to pre-order or purchase the Solaris Book Laptop. This course purchase is independent of any future product offerings, and Solaris Comtech makes no guarantees regarding product availability, discounted pricing, or preferential treatment for course purchasers..`}
        />
        <PragraphBox
          contentText="Refund Policy:"
          para={`All payments made for the digital course are final and non-refundable. By completing your purchase, you acknowledge that you are buying only the digital course content, and refunds will not be provided for any reason, including if you mistakenly believed it was connected to a pre-order or purchase of the Solaris Book Laptop.
`}
        />
        <PragraphBox
          contentText="Software License Terms:"
          para={`Any software offerings provided on this platform are extended to users under license agreements, not outright sale. It is important to note that while Solaris Comtech may include pre-installed software or operating systems on its products, these software packages are the proprietary creations of their respective companies.
Your engagement with any software applications or operating systems pre-installed on Solaris Comtech products necessitates strict compliance with the terms articulated within the accompanying license agreements provided by the respective software companies. Any transgression of these terms may result in the revocation of software privileges and potential legal ramifications.
Solaris Comtech acts solely as a distributor of these pre-installed software packages and does not claim ownership or authorship of the software itself. Users are encouraged to review and adhere to the license agreements provided by the respective software companies governing the use of these applications.
`}
        />
        <PragraphBox
          contentText="Updates and Modifications:"
          para={`Solaris Comtech reserves the right to update or modify these Terms and Conditions at any time without prior notice. Users are encouraged to periodically review these terms to stay updated on any changes. Continued use of the website after such modifications indicates acceptance of the revised terms.
`}
        />
        <PragraphBox
          contentText="Delivery of Digital Courses:"
          para={`After successful payment, you will receive an email containing a link to download the digital course content. This link will be available for 30 days, and you must download the course content to your computer or device within this period. Once downloaded, the content will remain available for your personal use indefinitely.
Please note that after the 30-day period, the download link will expire, and you will no longer be able to claim access to the course. Solaris Comtech is not responsible for providing access beyond the 30-day window.
`}
        />
        <PragraphBox
          contentText="Solaris Book Laptop Delivery:"
          para={`The Solaris Book Laptop is currently under development as part of the Make in India program, and Solaris Comtech is in the process of obtaining the necessary manufacturing licenses. Once production begins, the final pricing of the Solaris Book Laptop may differ from initial estimates. Users who express interest in the laptop by purchasing the digital course will be informed of any updates or pricing changes via email.
Please note that while we strive to expedite the manufacturing and delivery process, timelines may vary depending on factors such as production readiness, certification clearances, and logistical considerations. We appreciate your patience and understanding as we work diligently to ensure the quality and compliance of our products.
Once production is complete and a user has paid the full amount for the Solaris Book Laptop, we will deliver the laptop to the user’s registered address.
`}
        />
        <PragraphBox
          contentText="Disclaimers:"
          para={`Solaris Comtech disclaims any warranties or representations about the accuracy, completeness, or reliability of the information provided on the website. Users engage with the website and its associated content at their own risk.`}
        />
        <PragraphBox
          contentText="Limitation of Liability:"
          para={`Under no circumstances shall Solaris Comtech be liable for any direct, indirect, incidental, or consequential damages arising from your use of the website or any services related to the digital course.
.`}
        />
        <PragraphBox
          contentText="Indemnity:"
          para={`You agree to indemnify and hold Solaris Comtech harmless from any claims, damages, or liabilities arising from your breach of these terms or misuse of the website.
`}
        />
        <PragraphBox
          contentText="Governing Law and Dispute Resolution:"
          para={`These terms are governed by the laws of India. Any disputes arising from or related to these terms will be resolved in accordance with the Arbitration and Conciliation Act, 1996, within the jurisdiction of the competent courts of India.
.`}
        />
        <PragraphBox
          contentText="Updating these Terms:"
          para={`Solaris Comtech Laptop reserves the right to modify, amend, or update these terms and conditions at its sole discretion and without prior notice. Users are encouraged to periodically review these terms to stay abreast of any alterations or amendments. Continued usage of the website following such modifications implies tacit acceptance of the revised terms and conditions.
`}
        />
      </div>
      <Toaster position="bottom-left" reverseOrder={false} /> 
    </Layout>
  );
}

export default TermsConditions;